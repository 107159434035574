import React, { useCallback } from 'react';
import { LessonHomeworkItem } from '@lms-elements/atomic';
import { useWindowResize } from '@lms-elements/hooks';

import { LessonHomeworkWrapperProps } from './LessonHomeworkWrapper.types';

export const LessonHomeworkWrapper: React.FC<LessonHomeworkWrapperProps> = ({
    lesson,
    subjectId,
    lessonId,
    lessonTab,
    groupId,
    isNeedHideLessonButton,
    onLessonHomeworkClick,
}) => {
    const { isMobile } = useWindowResize();

    const handleLessonClick = useCallback(() => {
        if (onLessonHomeworkClick && subjectId && lessonId && groupId) {
            onLessonHomeworkClick(subjectId, lessonId, lessonTab, groupId);
        }
    }, [groupId, lessonId, lessonTab, onLessonHomeworkClick, subjectId]);

    const handleHomeworkClick = useCallback(() => {
        if (onLessonHomeworkClick && subjectId && lessonId && groupId) {
            onLessonHomeworkClick(subjectId, lessonId, 'tasks', groupId);
        }
    }, [groupId, lessonId, onLessonHomeworkClick, subjectId]);

    return (
        <LessonHomeworkItem
            {...lesson}
            onLessonClick={handleLessonClick}
            onHomeworkClick={handleHomeworkClick}
            isVertical={isMobile}
            isNeedHideLessonButton={isNeedHideLessonButton}
        />
    );
};
