export const getOverallHeaderLabel = (period: number, periodsQuantity: number): string => {
    if (period === periodsQuantity) {
        return 'Оценки за год';
    }

    switch (periodsQuantity) {
        case 4:
            return `${period + 1} четверть итог`;
        case 3:
            return `${period + 1} триместр итог`;
        case 2:
            return `${period + 1} семестр итог`;
        default:
            return `${period + 1} четверть итог`;
    }
};
