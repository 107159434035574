import React, { useCallback, useMemo } from 'react';
import { cn } from '@bem-react/classname';
import { DateHomeworkItem } from '@lms-elements/atomic';
import { checkIsBefore } from '@lms-elements/utils';

import { getLessonHeight } from '../../utils';

import { WeekScheduleListItemProps } from './WeekScheduleListItem.types';

const CnCalendarWeek = cn('calendarWeek');

export const WeekScheduleListItem: React.FC<WeekScheduleListItemProps> = ({ lesson, isShow, isEmpty, onItemClick }) => {
    const { data } = lesson;
    const style = useMemo(() => getLessonHeight(lesson), [lesson]);

    const handleItemClick = useCallback(() => {
        if (!isEmpty && data && data?.subject?.id && data?.lesson?.id && data?.courseGroup?.id) {
            const lessonTab = checkIsBefore(data.datetimeEnd) ? 'attendance' : 'materials';

            onItemClick?.(data.subject?.id, data.lesson?.id, lessonTab, data.courseGroup?.id);
        }
    }, [data, isEmpty, onItemClick]);

    if (!isEmpty && data) {
        return (
            <li style={style}>
                {isShow && (
                    <div className={CnCalendarWeek('link')} onClick={handleItemClick}>
                        <DateHomeworkItem
                            {...data}
                            lessonTitle={data?.lesson?.title ?? ''}
                            groupTitle={data?.courseGroup?.title ?? ''}
                            subjectTitle={data?.subject?.title ?? ''}
                            isSelfPreparation={data.type === 'selfpreparation_lesson'}
                        />
                    </div>
                )}
            </li>
        );
    }

    return <li style={style}></li>;
};
