import React from 'react';

import { CalendarDayHomework } from '../../CalendarDayHomeworkList';

import { MobileCalendarDayHomeworkProps } from './MobileCalendarDayHomework.types';

export const MobileCalendarDayHomework: React.FC<MobileCalendarDayHomeworkProps> = ({
    taskCheck,
    homeworks,
    onHomeworkClick,
    isStudent,
}) => (
    <div>
        {taskCheck && (
            <CalendarDayHomework homeworks={homeworks} onHomeworkClick={onHomeworkClick} isStudent={isStudent} />
        )}
    </div>
);
