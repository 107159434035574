import { HomeworkItemProps, LessonHomeworkItemProps } from '@lms-elements/atomic';
import { getDateComponents } from '@lms-elements/utils';

import { ScheduleEvent } from '../ScheduleBoard.types';

import { normalizeLessonsData } from './normalizeLessonsData';

interface TableLessonData {
    [key: string]: {
        time: string;
        data: LessonHomeworkItemProps[];
    };
}

export const getTabletLessons = (
    timeArray: string[],
    lessons: ScheduleEvent[],
    tasks: HomeworkItemProps[],
): TableLessonData => {
    return timeArray.reduce((result, time) => {
        const hours = time === '24:00' ? 0 : Number(time.split(':')[0]);
        const data = lessons.filter((lessons) => getDateComponents(lessons.datetimeStart).hours === hours);

        result[time] = {
            data: normalizeLessonsData(data, tasks).lessons,
            time,
        };
        return result;
    }, {} as TableLessonData);
};
