import React, { useMemo, useState } from 'react';
import { cn } from '@bem-react/classname';
import { Tabs } from '@lms-elements/atomic';

import { DayScheduleListTablet } from '../DayScheduleListTablet';

import { DayScheduleMobileProps } from './DayScheduleMobile.types';
import { MobileCalendarDayHomework } from './MobileCalendarDayHomework';

import './DayScheduleMobile.scss';

const cx = cn('mobile-calendar-day');

export const DayScheduleMobile: React.FC<DayScheduleMobileProps> = ({
    infoTitle,
    hasLessons,
    hasHomeworks,
    isStudent,
    taskCheck,
    homeworks,
    onHomeworkClick,
    lessons,
    assignedHomeworks,
    lessonCheck,
    meetingCheck,
    isNeedHideLessonButton,
    onScheduleItemClick,
}) => {
    const [selectedTab, setSelectedTab] = useState(0);

    const tabs = useMemo(() => {
        if (!hasLessons) {
            return [
                {
                    title: 'Д/З и проверка',
                    component: (
                        <MobileCalendarDayHomework
                            isStudent={isStudent}
                            taskCheck={taskCheck}
                            homeworks={homeworks}
                            onHomeworkClick={onHomeworkClick}
                        />
                    ),
                    id: 0,
                },
            ];
        }

        if (!hasHomeworks) {
            return [
                {
                    title: 'Занятия',
                    component: (
                        <div>
                            <div className={cx('infoTitle')}>{infoTitle}</div>
                            <DayScheduleListTablet
                                lessons={lessons}
                                lessonCheck={lessonCheck}
                                meetingCheck={meetingCheck}
                                assignedHomeworks={assignedHomeworks}
                                onScheduleItemClick={onScheduleItemClick}
                                isStudent={isStudent}
                                isNeedHideLessonButton={isNeedHideLessonButton}
                            />
                        </div>
                    ),
                    id: 0,
                },
            ];
        }

        return [
            {
                title: 'Занятия',
                component: (
                    <div>
                        <div className={cx('infoTitle')}>{infoTitle}</div>
                        <DayScheduleListTablet
                            lessons={lessons}
                            lessonCheck={lessonCheck}
                            meetingCheck={meetingCheck}
                            assignedHomeworks={assignedHomeworks}
                            onScheduleItemClick={onScheduleItemClick}
                            isStudent={isStudent}
                            isNeedHideLessonButton={isNeedHideLessonButton}
                        />
                    </div>
                ),
                id: 0,
            },
            {
                title: 'Д/З и проверка',
                component: (
                    <MobileCalendarDayHomework
                        isStudent={isStudent}
                        taskCheck={taskCheck}
                        homeworks={homeworks}
                        onHomeworkClick={onHomeworkClick}
                    />
                ),
                id: 1,
            },
        ];
    }, [
        hasLessons,
        hasHomeworks,
        infoTitle,
        lessons,
        lessonCheck,
        meetingCheck,
        assignedHomeworks,
        onScheduleItemClick,
        isStudent,
        isNeedHideLessonButton,
        taskCheck,
        homeworks,
        onHomeworkClick,
    ]);

    return (
        <div className={cx()}>
            <Tabs elements={tabs} selectedElement={selectedTab} onSelectedElementChange={setSelectedTab} />
        </div>
    );
};
