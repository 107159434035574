import { ICourseTopic, IMark, Student } from '../types';

export const getMarksByStudent = (
    students: Student[],
    topics: ICourseTopic[],
    marks: IMark[],
): {
    [student: number]: {
        [topic: number]: {
            [lesson: number]: IMark[];
        };
    };
} => {
    return students.reduce(
        (result, student) => {
            result[student.id] = topics.reduce(
                (resultTopic, topic) => {
                    resultTopic[topic.id] = topic.lessons.reduce(
                        (resultLesson, lesson) => {
                            resultLesson[lesson.id] = marks.filter(
                                (mark) =>
                                    mark.topic === topic.id && mark.lesson === lesson.id && mark.student === student.id,
                            );
                            return resultLesson;
                        },
                        {} as {
                            [lesson: number]: IMark[];
                        },
                    );

                    return resultTopic;
                },
                {} as {
                    [topic: number]: {
                        [lesson: number]: IMark[];
                    };
                },
            );

            return result;
        },
        {} as {
            [student: number]: {
                [topic: number]: {
                    [lesson: number]: IMark[];
                };
            };
        },
    );
};
