import { IQuarterMark } from '../types';

interface QuarterMarksByCourse {
    [courseId: number]: IQuarterMark | undefined;
}

export const getQuarterMarksByCourse = (
    quarterMarks: IQuarterMark[],
    courses?: { id: number }[],
): QuarterMarksByCourse => {
    return (
        courses?.reduce((result, course) => {
            const mark = quarterMarks.find((mark) => mark.subject === course.id);
            if (mark) {
                result[course.id] = mark;
            }

            return result;
        }, {} as QuarterMarksByCourse) || {}
    );
};
