import { getDateComponents } from '@lms-elements/utils';

import { ScheduleData } from '../ScheduleBoard.types';

export const getWeekStartAndEndTime = (data: ScheduleData): [number, number] => {
    const scheduleItems = Object.values(data).map(({ data }) => data);
    const result = scheduleItems.reduce(
        (result, item) => {
            if (item.length) {
                const startDate = new Date(item[0]?.datetimeStart);
                const startTime = getDateComponents(startDate).hours;

                const endDate =
                    item.length > 1 ? new Date(item[item.length - 1]?.datetimeEnd) : new Date(item[0]?.datetimeEnd);
                const endTime = getDateComponents(endDate).hours;

                if (result[0] === 0 || result[0] > startTime) {
                    result[0] = startTime;
                }

                if (result[1] === 0 || result[1] < endTime) {
                    result[1] = endTime;
                }
            }

            return result;
        },
        [0, 0] as [number, number],
    );

    return result;
};
