import { HomeworkItemProps, LessonHomeworkItemProps } from '@lms-elements/atomic';

import { DeferredTask, ScheduleEvent } from '../ScheduleBoard.types';

interface NormalizedLessonData {
    lessons: LessonHomeworkItemProps[];
    deferredTasks: DeferredTask[];
}

export const normalizeLessonsData = (
    lessons: ScheduleEvent[],
    homeworks: HomeworkItemProps[],
): NormalizedLessonData => {
    const scheduleLessons = lessons.map((lesson) => {
        const lessonHomeworks = homeworks.filter(
            (homework) => homework.lessonId === lesson.lesson.id && homework.courseGroupId === lesson.courseGroup.id,
        );
        const hasAssignments = lessonHomeworks.length > 0;
        const givenHometaskTitle = hasAssignments ? `${lessonHomeworks.length}ДЗ` : '';

        return {
            ...lesson,
            autoIssuetotalTasks: lessonHomeworks.length,
            autoIssuetasks: lessonHomeworks.length,
            link: '',
            autoIssueTime: '',
            passOffTasks: 0,
            toCheckTasks: 0,
            autoIssue: false,
            autoIssueSetUp: false,
            givenHometaskTitle,
        };
    });

    const deferredTasks = homeworks
        .filter((homework) => !lessons.some((lesson) => homework.lessonId === lesson.lesson.id))
        .reduce((homeworkStatuses, homework) => {
            const homeworkIndex = homeworkStatuses.findIndex(
                (status) => status.lessonId === homework.lessonId && status.autoIssueTime === homework.assignedDate,
            );

            if (homeworkIndex > -1) {
                homeworkStatuses[homeworkIndex] = {
                    ...homeworkStatuses[homeworkIndex],
                    autoIssuetasks: homeworkStatuses[homeworkIndex].autoIssuetasks + 1,
                    autoIssuetotalTasks: homeworkStatuses[homeworkIndex].autoIssuetotalTasks + 1,
                };
            } else {
                homeworkStatuses.push({
                    id: Number(homework.taskId),
                    lessonId: homework.lessonId,
                    subjectId: homework.subjectId,
                    courseGroupId: homework.courseGroupId,
                    status: 'auto',
                    autoIssue: true,
                    autoIssueSetUp: true,
                    autoIssuetotalTasks: 1,
                    autoIssuetasks: 1,
                    autoIssueTime: homework.assignedDate ?? '',
                    givenHometaskTitle: '',
                    passOffTasks: 0,
                    toCheckTasks: 0,
                });
            }

            return homeworkStatuses;
        }, [] as DeferredTask[]);

    return {
        deferredTasks,
        lessons: scheduleLessons,
    };
};
