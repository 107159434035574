import { getCalendarWeekTitle, getWeekdayNumber } from '@lms-elements/utils';

import { HomeworkObject, ScheduleData, WeekLessonsData } from '../ScheduleBoard.types';

type GetWeekLessonDataParams = {
    dateArray: string[];
    lessonsData: ScheduleData;
    tasksData?: HomeworkObject;
    createTaskLabel?: (taskCount: number) => string;
    createCalendarLessonLabel?: (lessonCount: number) => string;
    createMeetingLabel?: (count: number) => string;
};

export const getWeekLessonData = ({
    dateArray,
    lessonsData,
    tasksData,
    createCalendarLessonLabel = (count) => `${count} занятие(ий)`,
    createTaskLabel = (count: number) => `${count} ДЗ`,
    createMeetingLabel = (count: number) => `${count} собрание(ий)`,
}: GetWeekLessonDataParams): WeekLessonsData[] => {
    return dateArray.map((date, index) => {
        const weekDay = getWeekdayNumber(date);
        const dateData = lessonsData[date]?.data || [];
        const title = getCalendarWeekTitle(date);

        const lessonsCount = dateData.filter((value) => value.type !== 'meeting').length;
        const meetingsCount = dateData.filter((value) => value.type === 'meeting').length;

        const tasksCount = tasksData?.[date]?.data?.length ?? 0;

        const noJob = lessonsCount === 0 && meetingsCount === 0 && tasksCount === 0;
        const isShortText = lessonsCount !== 0 && meetingsCount !== 0 && tasksCount !== 0;

        let totalLessons = noJob ? 'Выходной' : '';

        if (weekDay === 0 && !noJob) {
            totalLessons = `Всего - ${lessonsCount} занятий`;
        }

        if (weekDay !== 0 && isShortText) {
            totalLessons = `${lessonsCount} зан, ${meetingsCount} собр, ${tasksCount} дз`;
        }

        if (!isShortText && !noJob && weekDay !== 0) {
            const lessonText = lessonsCount
                ? `${createCalendarLessonLabel(lessonsCount)}${meetingsCount || tasksCount ? ', ' : ''}`
                : '';
            const meetingText = meetingsCount ? `${createMeetingLabel(meetingsCount)}${tasksCount ? ', ' : ''}` : '';
            const taskText = tasksCount ? createTaskLabel(tasksCount) : '';
            totalLessons = `${lessonText}${meetingText}${taskText}`;
        }

        return {
            id: String(index),
            data: dateData,
            totalLessons,
            title,
        };
    });
};
