import React from 'react';
import { cn } from '@bem-react/classname';
import { HomeworkItem } from '@lms-elements/atomic';

import { CalendarWeekHomeworkListProps } from './CalendarWeekHomeworkList.types';

const CnCalendarWeek = cn('calendarWeek');

export const CalendarWeekHomeworkList: React.FC<CalendarWeekHomeworkListProps> = ({
    homeworkData,
    onHomeworkClick,
    isStudent,
}) => {
    return (
        <div className={CnCalendarWeek('homeworks')}>
            {homeworkData.map((homework) => (
                <ul key={homework.date} className={CnCalendarWeek('homeworkList')}>
                    {homework.data.map((item) => (
                        <li key={item.taskId} className={CnCalendarWeek('homeworkItem')}>
                            <HomeworkItem
                                {...item}
                                description={''}
                                isShort
                                isForPupils={isStudent}
                                onHomeworkClick={onHomeworkClick}
                            />
                        </li>
                    ))}
                </ul>
            ))}
        </div>
    );
};
