import React, { useMemo } from 'react';
import { cn } from '@bem-react/classname';
import { CalendarDayPointer } from '@lms-elements/icons';

import './ScheduleWithDisabledItems.scss';

const CnScheduleWithDisabledItems = cn('scheduleWithDisabledItems');

interface Props {
    lessonsCount: number;
    meetingsCount: number;
    homeworksCount: number;
    createMeetingLabel?: (mettingCount: number) => string;
    createCalendarLessonLabel?: (lessonCount: number) => string;
}

const createCalendarLessonLabelStab = (count: number) => `${count} занятие(ий)`;
const createMeetingLabelStab = (count: number) => `${count} собрание(ий)`;

export const ScheduleWithDisabledItems: React.FC<Props> = ({
    lessonsCount,
    meetingsCount,
    homeworksCount,
    createMeetingLabel = createMeetingLabelStab,
    createCalendarLessonLabel = createCalendarLessonLabelStab,
}) => {
    const title = useMemo(() => {
        return `${createCalendarLessonLabel(lessonsCount)}, ${createMeetingLabel(
            meetingsCount,
        )} и ${homeworksCount} дз`;
    }, [createCalendarLessonLabel, lessonsCount, createMeetingLabel, meetingsCount, homeworksCount]);

    return (
        <>
            <h2 className={CnScheduleWithDisabledItems('title')}>{title}</h2>
            <div className={CnScheduleWithDisabledItems('tooltip')}>
                <div className={CnScheduleWithDisabledItems('tooltipPointer')}>
                    <CalendarDayPointer />
                </div>
                <div className={CnScheduleWithDisabledItems('tooltipText')}>
                    Похоже вы отключили все типы активностей :(
                </div>
            </div>
        </>
    );
};
