import { HomeworkItemProps } from '@lms-elements/atomic';

import { HomeworkObject } from '../ScheduleBoard.types';

export const filterHomeworks = (
    dateArray: string[],
    homeworkObject: HomeworkObject,
): { date: string; data: HomeworkItemProps[] }[] => {
    const homeworksWithEmptyDays = { ...homeworkObject };

    const filteredHomeworks = dateArray.map((date) => ({
        date,
        data: homeworksWithEmptyDays[date]?.data || [],
    }));

    return filteredHomeworks;
};
