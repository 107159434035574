import React, { useCallback, useMemo, useRef } from 'react';
import { cn } from '@bem-react/classname';
import { useGetOnScrollHandler, useWindowResize } from '@lms-elements/hooks';
import { getStartOfWeek, getTimeArray, getWeekArray } from '@lms-elements/utils';

import { ScheduleWithDisabledItems } from '../ScheduleWithDisabledItems';
import {
    filterHomeworks,
    getHomeworksCount,
    getLessonsCount,
    getMeetingsCount,
    getWeekLessonData,
    getWeekStartAndEndTime,
} from '../utils';

import { CalendarWeekProps } from './CalendarWeek.types';
import { CalendarWeekHomeworkList } from './CalendarWeekHomeworkList';
import { WeekScheduleList } from './WeekScheduleList';

import './CalendarWeek.scss';

const CnCalendarWeek = cn('calendarWeek');

export const CalendarWeek: React.FC<CalendarWeekProps> = ({
    date,
    dataMap,
    tasksMap,
    taskCheck,
    lessonCheck,
    meetingCheck,
    isStudent,
    withBorder,
    onScheduleItemClick,
    createCalendarLessonLabel,
    createTaskLabel,
    createMeetingLabel,
}) => {
    const element = useRef(null);
    const { scrollTop, scrollLeft, handleContentScroll } = useGetOnScrollHandler(element);

    const startWeekDate = useMemo(() => getStartOfWeek(date), [date]);

    const dateArray = useMemo(() => getWeekArray(startWeekDate), [startWeekDate]);
    const [startTime, endTime] = useMemo(() => getWeekStartAndEndTime(dataMap), [dataMap]);
    const timeArray = useMemo(() => getTimeArray(startTime, endTime), [startTime, endTime]);

    const scheduleData = useMemo(
        () =>
            getWeekLessonData({
                dateArray,
                createCalendarLessonLabel,
                createTaskLabel,
                createMeetingLabel,
                lessonsData: dataMap,
                tasksData: tasksMap,
            }),
        [createCalendarLessonLabel, createMeetingLabel, createTaskLabel, dataMap, dateArray, tasksMap],
    );

    const homeworkData = useMemo(() => (tasksMap ? filterHomeworks(dateArray, tasksMap) : []), [tasksMap, dateArray]);

    const showLessons = useMemo(() => lessonCheck || meetingCheck, [lessonCheck, meetingCheck]);
    const allCheckboxesOff = useMemo(
        () => !lessonCheck && !meetingCheck && !taskCheck,
        [lessonCheck, meetingCheck, taskCheck],
    );

    const lessonsCount = useMemo(() => getLessonsCount(dataMap), [dataMap]);
    const meetingsCount = useMemo(() => getMeetingsCount(dataMap), [dataMap]);
    const homeworksCount = useMemo(() => (tasksMap ? getHomeworksCount(tasksMap) : 0), [tasksMap]);

    const handleHomeworkCardClick = useCallback(
        ({
            subjectId,
            groupId,
            lessonId,
        }: {
            subjectId: number;
            lessonId: number;
            taskId: number;
            groupId?: number;
        }) => {
            if (onScheduleItemClick && subjectId && groupId && lessonId) {
                onScheduleItemClick(subjectId, lessonId, 'tasks', groupId);
            }
        },
        [onScheduleItemClick],
    );

    const hasLessons = useMemo(
        () => scheduleData.reduce((totalLessons, { data }) => totalLessons + data.length, 0) !== 0,
        [scheduleData],
    );

    const empty = useMemo(
        () => !hasLessons && homeworkData.reduce((totalHomeworks, { data }) => totalHomeworks + data.length, 0) === 0,
        [hasLessons, homeworkData],
    );

    const { isMobile } = useWindowResize();

    if (allCheckboxesOff) {
        return (
            <div className={CnCalendarWeek()}>
                <ScheduleWithDisabledItems
                    lessonsCount={lessonsCount}
                    meetingsCount={meetingsCount}
                    homeworksCount={homeworksCount}
                    createCalendarLessonLabel={createCalendarLessonLabel}
                    createMeetingLabel={createMeetingLabel}
                />
            </div>
        );
    }

    return (
        <div className={CnCalendarWeek({ withBorder })}>
            <div className={CnCalendarWeek('headerContainer')}>
                <div className={CnCalendarWeek('header', { empty })} style={{ left: `-${scrollLeft}px` }}>
                    {scheduleData.map((day) => (
                        <div key={day.id} className={CnCalendarWeek('dayContainer')}>
                            <div className={CnCalendarWeek('dayTitle')}>{day.title}</div>
                            <div className={CnCalendarWeek('totalLessons')}>{day.totalLessons}</div>
                        </div>
                    ))}
                </div>
            </div>

            {showLessons && hasLessons && (
                <div className={CnCalendarWeek('timeContainer', { isMobile })}>
                    <ul className={CnCalendarWeek('timeline')} style={{ top: `-${scrollTop}px` }}>
                        {timeArray.map((time) => (
                            <li key={time} className={CnCalendarWeek('time')}>
                                {time}
                            </li>
                        ))}
                    </ul>
                </div>
            )}

            <div ref={element} className={CnCalendarWeek('content', { isMobile })} onScroll={handleContentScroll}>
                {showLessons && (
                    <WeekScheduleList
                        scheduleData={scheduleData}
                        lessonCheck={lessonCheck}
                        meetingCheck={meetingCheck}
                        startTime={startTime}
                        endTime={endTime}
                        onScheduleItemClick={onScheduleItemClick}
                    />
                )}
                {taskCheck && !isMobile && (
                    <CalendarWeekHomeworkList
                        isStudent={isStudent}
                        homeworkData={homeworkData}
                        onHomeworkClick={handleHomeworkCardClick}
                    />
                )}
            </div>
        </div>
    );
};
