import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { cn } from '@bem-react/classname';
import { Avatar, DropdownCustom, IDropdownType } from '@lms-elements/atomic';

import { PeriodTypes, SubjectType } from '../types';
import { getSortedStudents } from '../utils';

import { IPerformanceTableProps } from './PerformanceTable.types';
import { TableContainer } from './TableContainer';

import './PerformanceTable.scss';

const cnPerformanceTable = cn('performance-table');

const yearQuarter = 4;
const yearTrimester = 3;
const yearSemester = 2;

const createTeachersLabelStab = (count: number) => `${count} учителя(ей)`;

export const PerformanceTable: React.FC<IPerformanceTableProps> = ({
    students,
    courseAssignments,
    marks,
    quarterMarks,
    course,
    quarter,
    selectedCourse,
    isStudent,
    isParent,
    teachers,
    onSelectCourse,
    createTeacherLabel = createTeachersLabelStab,
    onChangeTab,
    onLessonClick,
    onStudentClick,
    isPeriodEnd,
    periodType = PeriodTypes.QUARTER,
    onMarkApprove,
    onMarkClick,
    onTeacherClick,
    isEmptyMark,
}) => {
    const [selectedQuarter, setSelectedQuarter] = useState(quarter || 0);

    const handleSelectQuarter = useCallback(
        (quarter: number) => {
            setSelectedQuarter(quarter);
            if (onChangeTab) {
                onChangeTab(quarter);
            }
        },
        [onChangeTab],
    );

    const sortedStudents = useMemo(() => getSortedStudents(students), [students]);

    const [isOpenedTeachers, setIsOpenedTeachers] = useState(false);

    const [selectedCourseAssignment, setSelectedCourseAssignment] = useState(
        courseAssignments.length ? courseAssignments[0].id : 0,
    );

    useEffect(() => {
        if (selectedCourse || courseAssignments[0]) {
            setSelectedCourseAssignment(selectedCourse || courseAssignments[0].id);
        }
    }, [selectedCourse, courseAssignments]);

    useEffect(() => {
        if (quarter !== undefined) {
            setSelectedQuarter(quarter);
        }
    }, [quarter]);

    const handleSelectSubject = useCallback(
        (subject: { id: number; title: string }) => {
            const courseAssignment = courseAssignments.find(
                (value) => value.groupId === subject.id && value.title === subject.title,
            );
            setSelectedCourseAssignment(courseAssignment?.id || 0);
            if (onSelectCourse && courseAssignment) {
                onSelectCourse(courseAssignment.courseId, courseAssignment.groupId);
            }
        },
        [courseAssignments, onSelectCourse],
    );

    const subjectsOptions = useMemo(
        () => courseAssignments.map((subject) => ({ id: subject.groupId, title: subject.title })),
        [courseAssignments],
    );

    const topicsByQuarter = useMemo(() => {
        if (course.length > 0) {
            const quarter = course[0].quarters.find((quarter) => quarter.quarter === selectedQuarter + 1);

            if (quarter) {
                return quarter.topics;
            }
        }

        return [];
    }, [selectedQuarter, course]);

    const marksBySubjectAndQuarter = useMemo(
        () => marks.filter((mark) => mark.quarter === selectedQuarter),
        [selectedQuarter, marks],
    );

    const isStudentView = isStudent || isParent;

    const periodsQuantity = useMemo(() => {
        switch (periodType) {
            case PeriodTypes.QUARTER:
                return yearQuarter;
            case PeriodTypes.SEMESTER:
                return yearSemester;
            case PeriodTypes.TRIMESTER:
                return yearTrimester;
            default:
                return yearQuarter;
        }
    }, [periodType]);

    const [tooltipState, setTooltipState] = useState({
        top: -9999,
        left: -9999,
    });

    const handleDocumentScroll = useCallback(() => {
        setIsOpenedTeachers(false);
    }, []);

    const handleCellMouseEnter = useCallback(
        (e: React.MouseEvent<HTMLDivElement> | React.TouchEvent<HTMLDivElement>) => {
            const card = e.currentTarget as HTMLDivElement;

            const { x, y } = card.getBoundingClientRect();
            setTooltipState({
                top: y + 30,
                left: x + 20,
            });

            document.addEventListener('touchmove', handleDocumentScroll);
            document.addEventListener('mousewheel', handleDocumentScroll);
            document.addEventListener('scroll', handleDocumentScroll);

            setIsOpenedTeachers(true);
        },
        [handleDocumentScroll],
    );

    const handleCellMouseLeave = useCallback(() => {
        setTooltipState({ top: -9999, left: -9999 });

        document.removeEventListener('touchmove', handleDocumentScroll);
        document.removeEventListener('mousewheel', handleDocumentScroll);
        document.removeEventListener('scroll', handleDocumentScroll);

        setIsOpenedTeachers(false);
    }, [handleDocumentScroll]);

    const quarterMarksByQuarter = useMemo(() => {
        if (isStudentView) {
            return selectedQuarter === periodsQuantity
                ? quarterMarks
                : quarterMarks.filter((mark) => mark.quarter === selectedQuarter);
        }

        if (selectedQuarter === periodsQuantity) {
            return quarterMarks.filter((mark) => mark.subject === selectedCourseAssignment);
        }

        return quarterMarks.filter(
            (mark) => mark.subject === selectedCourseAssignment && mark.quarter === selectedQuarter,
        );
    }, [isStudentView, selectedQuarter, periodsQuantity, quarterMarks, selectedCourseAssignment]);

    return (
        <div className={cnPerformanceTable({ isStudent: isStudentView })}>
            <div className={cnPerformanceTable('student-list', { isStudent: isStudentView })}>
                {!isStudentView && (
                    <div
                        className={cnPerformanceTable('student-list_header', {
                            sticky: !isStudentView,
                        })}
                    ></div>
                )}
                {!isStudentView && (
                    <div
                        className={cnPerformanceTable('student-list_dropdown', {
                            isNonSelectedCourse: selectedCourse === undefined,
                        })}
                    >
                        <DropdownCustom
                            name="subject"
                            isField
                            options={subjectsOptions}
                            validators={[]}
                            isNeedSearch
                            type={IDropdownType.optionWithId}
                            placeholder={'Выберите предмет'}
                            getValue={handleSelectSubject}
                        />
                    </div>
                )}
                {!isStudentView && (
                    <div
                        className={cnPerformanceTable('teacher-list')}
                        onMouseEnter={teachers && teachers.length > 1 ? handleCellMouseEnter : undefined}
                        onMouseLeave={handleCellMouseLeave}
                    >
                        {teachers && teachers.length === 1 ? (
                            <div
                                className={cnPerformanceTable('teacher-along')}
                                onClick={() => onTeacherClick?.(teachers[0].id)}
                            >
                                <Avatar
                                    name={`${teachers[0].lastName} ${teachers[0].firstName} `}
                                    source={teachers[0].photoUrl}
                                />
                            </div>
                        ) : (
                            <div className={cnPerformanceTable('teachers')}>
                                {teachers?.slice(0, 4).map((teacher) => {
                                    return <Avatar source={teacher.photoUrl} key={teacher.id} />;
                                })}
                                <div className={cnPerformanceTable('teacher-title')}>
                                    {teachers ? createTeacherLabel(teachers?.length) : ''}
                                </div>
                            </div>
                        )}
                        {isOpenedTeachers && (
                            <div className={cnPerformanceTable('teachers-tooltip')} style={tooltipState}>
                                <div className={cnPerformanceTable('tooltip-title')}>Учителя</div>
                                <div className={cnPerformanceTable('tooltip-main')}>
                                    {teachers?.map((teacher) => {
                                        return (
                                            <div key={teacher.id} onClick={() => onTeacherClick?.(teacher.id)}>
                                                <Avatar
                                                    source={teacher.photoUrl}
                                                    key={teacher.id}
                                                    name={`${teacher.lastName} ${teacher.firstName} ${teacher.patronymic}`}
                                                />
                                            </div>
                                        );
                                    })}
                                </div>
                            </div>
                        )}
                    </div>
                )}
                {!isStudentView && <p className={cnPerformanceTable('student-list_title')}>ученик</p>}
                {!isStudentView &&
                    sortedStudents.map((student) => (
                        <Avatar
                            name={`${student.lastName} ${student.firstName}`}
                            source={student.photoUrl}
                            key={`StudentAvatar${student.id}`}
                            onAvatarClick={onStudentClick?.bind(null, student.id)}
                        />
                    ))}
                {isStudentView && (
                    <div className={cnPerformanceTable('studentCourses', { isStudent: isStudentView })}>
                        {Object.values(SubjectType).map((type, index) => {
                            return (
                                <div key={type} className={cnPerformanceTable('cell-container')}>
                                    {index > 0 ? (
                                        (courseAssignments.map((el) => el.type).includes(SubjectType.NON_SUBJECT) &&
                                            type === SubjectType.NON_SUBJECT && (
                                                <div
                                                    key={type}
                                                    className={cnPerformanceTable('course-cell', { type: true })}
                                                >
                                                    <p
                                                        className={cnPerformanceTable('course-title', {
                                                            type: true,
                                                            isStudent: isStudentView,
                                                        })}
                                                    >
                                                        Внепредметные курсы
                                                    </p>
                                                </div>
                                            )) ||
                                        (courseAssignments.map((el) => el.type).includes(SubjectType.ELECTIVE) &&
                                            type === SubjectType.ELECTIVE && (
                                                <div
                                                    key={type}
                                                    className={cnPerformanceTable('course-cell', { type: true })}
                                                >
                                                    <p
                                                        className={cnPerformanceTable('course-title', {
                                                            type: true,
                                                            isStudent: isStudentView,
                                                        })}
                                                    >
                                                        Курсы по выбору
                                                    </p>
                                                </div>
                                            )) ||
                                        (courseAssignments.map((el) => el.type).includes(SubjectType.GIA_PREPARATION) &&
                                            type === SubjectType.GIA_PREPARATION && (
                                                <div
                                                    key={type}
                                                    className={cnPerformanceTable('course-cell', { type: true })}
                                                >
                                                    <p
                                                        className={cnPerformanceTable('course-title', {
                                                            type: true,
                                                            isStudent: isStudentView,
                                                        })}
                                                    >
                                                        Подготовка к ГИА
                                                    </p>
                                                </div>
                                            ))
                                    ) : (
                                        <div key={type} className={cnPerformanceTable('course-cell', { type: true })}>
                                            <p
                                                className={cnPerformanceTable('course-title', {
                                                    type: true,
                                                    isStudent: isStudentView,
                                                })}
                                            >
                                                Основные курсы
                                            </p>
                                        </div>
                                    )}

                                    {courseAssignments
                                        .filter((subject) => subject.type === type)
                                        .map((subject) => (
                                            <div key={subject.id} className={cnPerformanceTable('course-cell')}>
                                                <p
                                                    className={cnPerformanceTable('course-title', {
                                                        isStudent: isStudentView,
                                                    })}
                                                >
                                                    {subject.title}
                                                </p>
                                            </div>
                                        ))}
                                </div>
                            );
                        })}
                    </div>
                )}
            </div>
            <div className={cnPerformanceTable('table', { isStudent: isStudentView })}>
                <TableContainer
                    selectedQuarter={selectedQuarter}
                    handleSelectQuarter={handleSelectQuarter}
                    students={sortedStudents}
                    topics={topicsByQuarter}
                    marks={marksBySubjectAndQuarter}
                    quarterMarks={quarterMarksByQuarter}
                    onLessonClick={onLessonClick}
                    isStudent={isStudent}
                    courses={courseAssignments}
                    isParent={isParent}
                    periodsQuantity={periodsQuantity}
                    onMarkApprove={onMarkApprove}
                    selectedGroup={selectedCourseAssignment}
                    onMarkClick={onMarkClick}
                    isPeriodEnd={isPeriodEnd}
                    isEmptyMark={isEmptyMark}
                />
            </div>
        </div>
    );
};
