import React, { useCallback, useEffect } from 'react';
import { cn } from '@bem-react/classname';
import { Button, ButtonViewEnum, Mark } from '@lms-elements/atomic';

import { ApprovePopupContentProps } from './ApprovePopupContent.types';

import './ApprovePopupContent.scss';

const cnPopup = cn('pt-popup');

const APPROVE_TEXT = 'Выберите оценку, которую вы хотите поставить вместо существующей:';
const MARKS = [5, 4, 3, 2];
const TEST_MARKS = [true, false];
const SAVE_BUTTON_LABEL = 'Сохранить';
const CANCEL_BUTTON_LABEL = 'Отмена';

export const ApprovePopupContent: React.FC<ApprovePopupContentProps> = ({
    selectedMark,
    setSelectedMark,
    onSave,
    isTestMark,
    onCancel,
}) => {
    const handleMarkClick = useCallback(
        (params: { score: number | boolean }) => {
            if (typeof params.score === 'boolean') setSelectedMark(params.score);
            if (typeof params.score === 'number') setSelectedMark(Number(params.score));
        },
        [setSelectedMark],
    );

    useEffect(() => {
        document.body.style.overflow = 'hidden';

        return () => {
            document.body.style.overflow = 'revert';
        };
    }, []);

    return (
        <div className={cnPopup('main')}>
            <div className={cnPopup('text')}>{APPROVE_TEXT}</div>
            <div className={cnPopup('marks')}>
                {!isTestMark
                    ? MARKS.map((mark) => (
                          <div className={cnPopup('mark')} key={mark}>
                              <Mark
                                  value={mark}
                                  markId={''}
                                  isNotSelected={mark !== selectedMark}
                                  needHideWeight
                                  onMarkClick={handleMarkClick}
                              />
                          </div>
                      ))
                    : TEST_MARKS.map((mark, index) => (
                          <div className={cnPopup('mark')} key={index}>
                              <Mark
                                  isTestPassed={mark}
                                  markId={''}
                                  isNotSelected={mark !== selectedMark}
                                  needHideWeight
                                  onMarkClick={handleMarkClick}
                              />
                          </div>
                      ))}
            </div>
            <div className={cnPopup('buttons')}>
                <Button view={ButtonViewEnum.action} size="l" onClick={onSave}>
                    {SAVE_BUTTON_LABEL}
                </Button>
                <Button view={ButtonViewEnum.bordered} size="l" onClick={onCancel}>
                    {CANCEL_BUTTON_LABEL}
                </Button>
            </div>
        </div>
    );
};
