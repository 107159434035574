import React, { useCallback, useEffect, useState } from 'react';
import { cn } from '@bem-react/classname';
import { Checkbox, DateSlider, Tablets } from '@lms-elements/atomic';
import { useWindowResize } from '@lms-elements/hooks';

import { SchedulePageHeaderProps } from './SchedulePageHeader.types';

import './SchedulePageHeader.scss';

const CnSchedulerPageHeader = cn('schedulePageHeader');

export const SchedulePageHeader: React.FC<SchedulePageHeaderProps> = ({
    tablet,
    sliderTime,
    date,
    withCheckboxes,
    changeTablet,
    changeDate,
    changeCheckbox,
}) => {
    const [activeTab, setActiveTab] = useState(tablet);

    useEffect(() => {
        setActiveTab(tablet);
    }, [tablet]);

    const handleChangeTablet = useCallback(
        (title: string) => {
            setActiveTab(title);
            if (changeTablet) {
                changeTablet(title);
            }
        },
        [changeTablet],
    );

    const handleChangeDate = useCallback(
        (newDate: string) => {
            if (changeDate) {
                changeDate(newDate);
            }
        },
        [changeDate],
    );

    const handleCheckboxChange = useCallback(
        (e: React.ChangeEvent<HTMLInputElement>) => {
            const checkbox = e.target;

            if (changeCheckbox) {
                changeCheckbox(checkbox.name, checkbox.checked);
            }
        },
        [changeCheckbox],
    );

    const { isMobile } = useWindowResize();

    return (
        <div className={CnSchedulerPageHeader({ isMobile })}>
            <div className={CnSchedulerPageHeader('slider')}>
                <DateSlider
                    time={sliderTime}
                    changeDate={handleChangeDate}
                    date={date}
                    isLabelInCenter={isMobile}
                    weekWithLetterMonth
                />
            </div>
            <div className={CnSchedulerPageHeader('tablets')}>
                <Tablets
                    titles={['День', 'Неделя', 'Месяц']}
                    activeElement={activeTab}
                    setActiveElement={handleChangeTablet}
                    isResponsive
                />
            </div>
            {withCheckboxes && (
                <div className={CnSchedulerPageHeader('checkboxes')}>
                    <div className={CnSchedulerPageHeader('checkbox')}>
                        <Checkbox label="Уроки" name="lesson" onChange={handleCheckboxChange} defaultChecked />
                    </div>
                    <div className={CnSchedulerPageHeader('checkbox')}>
                        <Checkbox
                            label="Родит.собрания"
                            name="meeting"
                            onChange={handleCheckboxChange}
                            defaultChecked
                        />
                    </div>
                    <div className={CnSchedulerPageHeader('checkbox')}>
                        <Checkbox label="Проверки д/з" name="task" onChange={handleCheckboxChange} defaultChecked />
                    </div>
                </div>
            )}
        </div>
    );
};
