import React from 'react';
import { cn } from '@bem-react/classname';
import { HomeworkStatus } from '@lms-elements/atomic';
import { useWindowResize } from '@lms-elements/hooks';

import { DayScheduleItem } from '../DayScheduleItem';
import { useScheduleListData } from '../hooks/useScheduleListData';
import { TabletDayScheduleItem } from '../TabletDayScheduleItem';

import { DayScheduleListProps } from './DayScheduleList.types';

const CnCalendarDay = cn('calendarDay');

export const DayScheduleList: React.FC<DayScheduleListProps> = ({
    lessons,
    lessonCheck,
    meetingCheck,
    assignedHomeworks,
    isStudent,
    isNeedHideLessonButton,
    onScheduleItemClick,
}) => {
    const { isTablet } = useWindowResize();
    const {
        scheduleItems,
        deferredTasks,
        tabletData,
        isEmptyTabletData,
        handleLessonHomeworkClick,
        handleDeferredTaskClick,
    } = useScheduleListData({ lessons, assignedHomeworks, onScheduleItemClick });

    if (isTablet) {
        return (
            <ul className={CnCalendarDay('lessonsList')}>
                {!isEmptyTabletData &&
                    tabletData.map(({ time, data }) => (
                        <TabletDayScheduleItem
                            key={time}
                            time={time}
                            itemData={data}
                            meetingCheck={meetingCheck}
                            lessonCheck={lessonCheck}
                            onLessonHomeworkClick={handleLessonHomeworkClick}
                            isNeedHideLessonButton={isNeedHideLessonButton}
                        />
                    ))}
                {deferredTasks.map(({ lessonId, subjectId, courseGroupId, ...task }) => (
                    <li key={task.id} className={CnCalendarDay('deferredTasks')}>
                        <div
                            className={CnCalendarDay('taskWrapper')}
                            data-lesson={lessonId}
                            data-subject={subjectId}
                            data-group={courseGroupId}
                            data-task={task.id}
                            onClick={handleDeferredTaskClick}
                        >
                            <HomeworkStatus {...task} isStudent={isStudent} />
                        </div>
                    </li>
                ))}
            </ul>
        );
    }

    return (
        <ul className={CnCalendarDay('lessonsList')}>
            {scheduleItems.map((lesson, index) => {
                const { type, data } = lesson;
                const isEmptyLesson = type === 'empty';
                const isShow = (data?.type === 'meeting' && meetingCheck) || (data?.type !== 'meeting' && lessonCheck);

                return (
                    <DayScheduleItem
                        key={data?.id || `${lesson.startTime}-empty${index}`}
                        lesson={lesson}
                        isEmpty={isEmptyLesson}
                        isShow={isShow}
                        lessonId={data?.lesson?.id ?? 0}
                        subjectId={data?.subject?.id ?? 0}
                        onLessonHomeworkClick={handleLessonHomeworkClick}
                        isNeedHideLessonButton={isNeedHideLessonButton}
                    />
                );
            })}
            {deferredTasks.map(({ lessonId, subjectId, courseGroupId, ...task }) => (
                <li key={task.id} className={CnCalendarDay('deferredTasks')}>
                    <div
                        className={CnCalendarDay('taskWrapper')}
                        data-lesson={lessonId}
                        data-subject={subjectId}
                        data-group={courseGroupId}
                        data-task={task.id}
                        onClick={handleDeferredTaskClick}
                    >
                        <HomeworkStatus {...task} isStudent={isStudent} />
                    </div>
                </li>
            ))}
        </ul>
    );
};
