import React from 'react';
import { cn } from '@bem-react/classname';
import { HomeworkStatus } from '@lms-elements/atomic';
import { useWindowResize } from '@lms-elements/hooks';

import { useScheduleListData } from '../hooks/useScheduleListData';
import { TabletDayScheduleItem } from '../TabletDayScheduleItem';

import { DayScheduleListTabletProps } from './DayScheduleListTablet.types';

const CnCalendarDay = cn('calendarDay');

export const DayScheduleListTablet: React.FC<DayScheduleListTabletProps> = ({
    lessons,
    assignedHomeworks,
    isStudent,
    lessonCheck,
    meetingCheck,
    isNeedHideLessonButton,
    onScheduleItemClick,
}) => {
    const { isMobile } = useWindowResize();

    const { deferredTasks, tabletData, isEmptyTabletData, handleLessonHomeworkClick, handleDeferredTaskClick } =
        useScheduleListData({ lessons, assignedHomeworks, onScheduleItemClick });

    return (
        <ul className={CnCalendarDay('lessonsList')}>
            {!isEmptyTabletData &&
                tabletData.map(({ time, data }) => (
                    <TabletDayScheduleItem
                        key={time}
                        time={time}
                        itemData={data}
                        meetingCheck={meetingCheck}
                        lessonCheck={lessonCheck}
                        onLessonHomeworkClick={handleLessonHomeworkClick}
                        isNeedHideLessonButton={isNeedHideLessonButton}
                    />
                ))}
            {deferredTasks.map(({ lessonId, subjectId, courseGroupId, ...task }) => (
                <li key={task.id} className={CnCalendarDay('deferredTasks')}>
                    <div
                        className={CnCalendarDay('taskWrapper', { isMobile })}
                        data-lesson={lessonId}
                        data-subject={subjectId}
                        data-group={courseGroupId}
                        data-task={task.id}
                        onClick={handleDeferredTaskClick}
                    >
                        <HomeworkStatus {...task} isStudent={isStudent} />
                    </div>
                </li>
            ))}
        </ul>
    );
};
