import React, { useCallback, useMemo, useRef } from 'react';
import { cn } from '@bem-react/classname';
import { HomeworkItemProps } from '@lms-elements/atomic';
import { useGetOnScrollHandler, useWindowResize } from '@lms-elements/hooks';
import { getDiffBetweenDates, getTimeArray } from '@lms-elements/utils';
import { emptyCalendarDay } from 'assets';

import { ScheduleEvent } from '../ScheduleBoard.types';
import { ScheduleWithDisabledItems } from '../ScheduleWithDisabledItems';
import { getinfoTitle, getStartAndEndTime, getTitle } from '../utils';

import { CalendarDayHomework } from './CalendarDayHomeworkList/CalendarDayHomeworkList';
import { DayScheduleList } from './DayScheduleList/DayScheduleList';
import { CalendarDayProps } from './CalendarDay.types';
import { DayScheduleListTablet } from './DayScheduleListTablet';
import { DayScheduleMobile } from './DayScheduleMobile';

import './CalendarDay.scss';

const CnCalendarDay = cn('calendarDay');

const lessonsStab = [] as ScheduleEvent[];
const homeworksStab = [] as HomeworkItemProps[];

export const CalendarDay: React.FC<CalendarDayProps> = ({
    lessons = lessonsStab,
    homeworks = homeworksStab,
    assignedHomeworks = homeworksStab,
    lessonCheck,
    meetingCheck,
    taskCheck,
    isStudent,
    isNeedHideLessonButton,
    onScheduleItemClick,
    createTaskLabel,
    createLessonLabel,
    createCalendarLessonLabel,
    createMeetingLabel,
}) => {
    const element = useRef(null);
    const { scrollTop, handleContentScroll } = useGetOnScrollHandler(element);

    const { isTablet, isMobile } = useWindowResize();

    const lessonsCount = lessons.filter((lesson) => lesson.type !== 'meeting').length;
    const meetingsCount = lessons.filter((lesson) => lesson.type === 'meeting').length;

    const homeworksCount = homeworks.length;

    const hasLessons = lessons.length > 0;
    const hasAssignedHomeworks = assignedHomeworks.length > 0;
    const lessonsHided = !lessonCheck && !meetingCheck;
    const hasHomeworks = homeworksCount > 0;
    const isEmpty = !hasLessons && !hasHomeworks && !hasAssignedHomeworks;
    const allCheckboxesOff = !lessonCheck && !meetingCheck && !taskCheck;

    const title = useMemo(
        () => getTitle(lessons, homeworks, allCheckboxesOff, isStudent, createCalendarLessonLabel),
        [lessons, homeworks, allCheckboxesOff, isStudent, createCalendarLessonLabel],
    );

    const infoTitle = useMemo(
        () =>
            getinfoTitle({
                lessons,
                homeworks,
                assignedHomeworks,
                isStudent,
                createTaskLabel,
                createLessonLabel,
            }),
        [lessons, homeworks, assignedHomeworks, isStudent, createTaskLabel, createLessonLabel],
    );

    const sortedLessons = useMemo(() => {
        return lessons.slice().sort((a, b) => getDiffBetweenDates(a.datetimeStart, b.datetimeStart));
    }, [lessons]);
    const [start, end] = useMemo(() => getStartAndEndTime(sortedLessons), [sortedLessons]);

    const timeArray = useMemo(() => getTimeArray(start, end), [start, end]);

    const handleHomeworkCardClick = useCallback(
        ({
            subjectId,
            lessonId,
            groupId,
        }: {
            subjectId: number;
            lessonId: number;
            taskId: number;
            groupId?: number;
        }) => {
            if (onScheduleItemClick && subjectId && lessonId && groupId) {
                onScheduleItemClick(subjectId, lessonId, 'tasks', groupId);
            }
        },
        [onScheduleItemClick],
    );

    if (isEmpty) {
        return (
            <div className={CnCalendarDay({ isEmpty })}>
                <h2 className={CnCalendarDay('title', { isEmpty })}>{title}</h2>
                <div className={CnCalendarDay('image-wrapper')}>
                    <img src={emptyCalendarDay} className={CnCalendarDay('image')} alt="jpg" />
                </div>
            </div>
        );
    }

    if (allCheckboxesOff) {
        return (
            <div className={CnCalendarDay()}>
                <ScheduleWithDisabledItems
                    lessonsCount={lessonsCount}
                    meetingsCount={meetingsCount}
                    homeworksCount={homeworksCount}
                    createMeetingLabel={createMeetingLabel}
                    createCalendarLessonLabel={createCalendarLessonLabel}
                />
            </div>
        );
    }

    if (isMobile) {
        return (
            <DayScheduleMobile
                infoTitle={infoTitle}
                hasLessons={hasLessons || hasAssignedHomeworks}
                hasHomeworks={hasHomeworks}
                isStudent={isStudent}
                taskCheck={taskCheck}
                homeworks={homeworks}
                onHomeworkClick={handleHomeworkCardClick}
                lessons={sortedLessons}
                lessonCheck={lessonCheck}
                meetingCheck={meetingCheck}
                assignedHomeworks={assignedHomeworks}
                onScheduleItemClick={onScheduleItemClick}
                isNeedHideLessonButton={isNeedHideLessonButton}
            />
        );
    }

    return (
        <div className={CnCalendarDay()}>
            <div className={CnCalendarDay('header')}>
                {!lessonsHided && <h4 className={CnCalendarDay('title')}>{title}</h4>}
                <div className={CnCalendarDay('infoTitle')}>{infoTitle}</div>
            </div>

            {hasLessons && !isTablet && !lessonsHided && (
                <div className={CnCalendarDay('timeContainer')}>
                    <ul className={CnCalendarDay('timeline')} style={{ top: `-${scrollTop}px` }}>
                        {timeArray.map((time) => (
                            <li key={time} className={CnCalendarDay('time')}>
                                {time === '24:00' ? '00:00' : time}
                            </li>
                        ))}
                    </ul>
                </div>
            )}

            <div ref={element} className={CnCalendarDay('content')} onScroll={handleContentScroll}>
                {((hasLessons && !lessonsHided) || (hasAssignedHomeworks && taskCheck)) && (
                    <div className={CnCalendarDay('schedule')}>
                        {isTablet ? (
                            <DayScheduleListTablet
                                lessons={sortedLessons}
                                lessonCheck={lessonCheck}
                                meetingCheck={meetingCheck}
                                assignedHomeworks={assignedHomeworks}
                                onScheduleItemClick={onScheduleItemClick}
                                isStudent={isStudent}
                                isNeedHideLessonButton={isNeedHideLessonButton}
                            />
                        ) : (
                            <DayScheduleList
                                lessons={sortedLessons}
                                lessonCheck={lessonCheck}
                                meetingCheck={meetingCheck}
                                assignedHomeworks={assignedHomeworks}
                                onScheduleItemClick={onScheduleItemClick}
                                isStudent={isStudent}
                                isNeedHideLessonButton={isNeedHideLessonButton}
                            />
                        )}
                    </div>
                )}
                {taskCheck && (
                    <CalendarDayHomework
                        homeworks={homeworks}
                        onHomeworkClick={handleHomeworkCardClick}
                        isStudent={isStudent}
                    />
                )}
            </div>
        </div>
    );
};
