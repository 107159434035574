import React, { useMemo } from 'react';
import { cn } from '@bem-react/classname';
import { HomeworkItem } from '@lms-elements/atomic';

import { CalendarDayHomeworkProps } from './CalendarDayHomeworkList.types';

const CnCalendarDay = cn('calendarDay');

export const CalendarDayHomework: React.FC<CalendarDayHomeworkProps> = ({ homeworks, onHomeworkClick, isStudent }) => {
    const homeworkTitle = useMemo(() => {
        if (homeworks.length === 0) {
            return 'Заданий нет';
        }
        return isStudent ? 'Необходимо сдать в этот день' : 'Необходимо проверить в этот день';
    }, [homeworks.length, isStudent]);

    return (
        <div className={CnCalendarDay('homeworks')}>
            <h4 className={CnCalendarDay('homeworkTitle')}>{homeworkTitle}</h4>
            <ul className={CnCalendarDay('homeworksList')}>
                {homeworks.map((homework) => (
                    <li key={homework.taskId}>
                        <HomeworkItem
                            {...homework}
                            description={''}
                            onHomeworkClick={onHomeworkClick}
                            isForPupils={isStudent}
                        />
                    </li>
                ))}
            </ul>
        </div>
    );
};
