import { IQuarterMark } from '../types';

export const getOverallQuarterMarksByCourse = (
    courses: { id: number }[],
    marks: IQuarterMark[],
): {
    [course: number]: {
        [quarter: number]: IQuarterMark | undefined;
    };
} => {
    return courses.reduce(
        (result, course) => {
            result[course.id] = [0, 1, 2, 3, 4].reduce(
                (resultQuarter, quarter) => {
                    const value = marks.find((mark) => mark.quarter === quarter && mark.subject === course.id);
                    resultQuarter[quarter] = value;
                    return resultQuarter;
                },
                {} as {
                    [quarter: number]: IQuarterMark | undefined;
                },
            );
            return result;
        },
        {} as {
            [course: number]: {
                [quarter: number]: IQuarterMark | undefined;
            };
        },
    );
};
