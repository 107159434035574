import { HomeworkItemProps } from '@lms-elements/atomic';

import { ScheduleEvent } from '../ScheduleBoard.types';

type GetInfoTitleParams = {
    lessons: ScheduleEvent[];
    homeworks: HomeworkItemProps[];
    assignedHomeworks: HomeworkItemProps[];
    isStudent?: boolean;
    createTaskLabel?: (taskCount: number) => string;
    createLessonLabel?: (lessonCount: number) => string;
};

export const getinfoTitle = (params: GetInfoTitleParams): string => {
    const {
        lessons,
        homeworks,
        isStudent,
        assignedHomeworks,
        createLessonLabel = (count: number) => `${count} урок(ов)`,
        createTaskLabel = (count: number) => `${count} ДЗ`,
    } = params;
    const hasLessons = lessons.length > 0;
    const hasHomeworks = homeworks.length > 0;
    const hasAssignedHomeworks = assignedHomeworks.length > 0;

    const lessonsInfo = hasLessons ? createLessonLabel(lessons.length) : '';
    const homeworksInfo = hasHomeworks ? `${createTaskLabel(homeworks.length)}${isStudent ? '' : ' на проверку'}` : '';
    const assignedHomeworksInfo = hasAssignedHomeworks
        ? `${createTaskLabel(assignedHomeworks.length)}${isStudent ? '' : ' задано'}`
        : '';

    if (hasLessons) {
        return `Всего - ${lessonsInfo}${hasHomeworks ? ', ' + homeworksInfo : ''}${
            hasAssignedHomeworks ? ', ' + assignedHomeworksInfo : ''
        }`;
    }

    if (hasHomeworks) {
        return `Всего - ${homeworksInfo}${hasAssignedHomeworks ? ', ' + assignedHomeworksInfo : ''}`;
    }

    return `Всего - ${assignedHomeworksInfo}`;
};
