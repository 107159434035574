import { useCallback, useMemo } from 'react';
import { HomeworkItemProps, LessonHomeworkItemProps } from '@lms-elements/atomic';
import { getTimeArray } from '@lms-elements/utils';

import { DeferredTask, Lesson, ScheduleEvent } from '../../ScheduleBoard.types';
import { createRenderLessonsArray, getStartAndEndTime, getTabletLessons } from '../../utils';

type ScheduleListHookResult = {
    scheduleItems: Lesson[];
    deferredTasks: DeferredTask[];
    tabletData: {
        time: string;
        data: LessonHomeworkItemProps[];
    }[];
    isEmptyTabletData?: boolean;
    handleLessonHomeworkClick: (subjectId?: number, conferenceId?: number, tab?: string, groupId?: number) => void;
    handleDeferredTaskClick: (e: React.MouseEvent<HTMLDivElement>) => void;
};

type ScheduleListHookParams = {
    lessons: ScheduleEvent[];
    assignedHomeworks: HomeworkItemProps[];
    onScheduleItemClick?: (
        subjectId: number,
        lessonId: number,
        tab: string,
        groupId?: number | undefined,
        task?: number | undefined,
    ) => void;
};

export const useScheduleListData = ({
    lessons,
    assignedHomeworks,
    onScheduleItemClick,
}: ScheduleListHookParams): ScheduleListHookResult => {
    const [start, end] = useMemo(() => getStartAndEndTime(lessons), [lessons]);
    const { lessons: scheduleItems, deferredTasks } = useMemo(
        () =>
            createRenderLessonsArray({
                start,
                end,
                lessons,
                tasks: assignedHomeworks,
            }),
        [start, end, lessons, assignedHomeworks],
    );
    const timeArray = useMemo(() => getTimeArray(start, end), [start, end]);
    const tabletData = useMemo(
        () => Object.values(getTabletLessons(timeArray, lessons, assignedHomeworks)),
        [timeArray, lessons, assignedHomeworks],
    );

    const isEmptyTabletData = !tabletData.some(({ data }) => data.length > 0);

    const handleLessonHomeworkClick = useCallback(
        (subjectId?: number, conferenceId?: number, tab?: string, groupId?: number) => {
            if (onScheduleItemClick && subjectId && conferenceId && tab) {
                onScheduleItemClick(subjectId, conferenceId, tab, groupId);
            }
        },
        [onScheduleItemClick],
    );

    const handleDeferredTaskClick = useCallback(
        (e: React.MouseEvent<HTMLDivElement>) => {
            const { subject, lesson, group, task } = e.currentTarget.dataset as {
                subject?: string;
                lesson?: string;
                group?: string;
                task: string;
            };

            if (subject && lesson && onScheduleItemClick && group) {
                onScheduleItemClick(Number(subject), Number(lesson), 'tasks', Number(group), Number(task));
            }
        },
        [onScheduleItemClick],
    );

    return {
        scheduleItems,
        deferredTasks,
        tabletData,
        isEmptyTabletData,
        handleLessonHomeworkClick,
        handleDeferredTaskClick,
    };
};
