import React, { ReactNode, useMemo, useRef } from 'react';
import { cn } from '@bem-react/classname';
import { ITabsElement, Tabs } from '@lms-elements/atomic';
import { useGetOnScrollHandler, useWindowResize } from '@lms-elements/hooks';
import { emptyMark } from 'assets';

import { getTableTabTitle } from '../../utils';
import { Table } from '../Table';
import { TableOverall } from '../TableOverall';

import { ITableContainerProps } from './TableConatainer.types';

import './TableContainer.scss';

const cnTableContainer = cn('performance-table-container');

export const TableContainer: React.FC<ITableContainerProps> = ({
    courses,
    LoaderComponent,
    isParent,
    isStudent,
    selectedGroup,
    marks,
    quarterMarks,
    selectedQuarter,
    isPeriodEnd,
    students,
    topics,
    handleSelectQuarter,
    onLessonClick,
    periodsQuantity = 4,
    onMarkApprove,
    onMarkClick,
    isEmptyMark,
}) => {
    const isStudentView = useMemo(() => isStudent || isParent, [isParent, isStudent]);

    const tableRef = useRef(null);
    const { isMobile } = useWindowResize();
    const { scrollLeft, handleContentScroll } = useGetOnScrollHandler(tableRef);

    const approvedPeriodMarks = useMemo(
        () =>
            quarterMarks.findIndex((el) => el.isTest) !== -1
                ? quarterMarks.filter((mark) => !!mark.testApproved)
                : quarterMarks.filter((mark) => !!mark.score),
        [quarterMarks],
    );

    const getTable: ReactNode = useMemo(() => {
        if (selectedQuarter < periodsQuantity) {
            return (
                <div className={cnTableContainer('wrapper', { isEmptyMark: isEmptyMark })}>
                    {isMobile && (
                        <div className={cnTableContainer('left-overlay', { transparent: scrollLeft === 0 })}></div>
                    )}
                    {!isEmptyMark ? (
                        <div
                            className={cnTableContainer({
                                isEmptyTopics: !topics.length,
                                isEndQuarter: selectedQuarter !== periodsQuantity,
                                isStudent: isStudentView,
                            })}
                            ref={tableRef}
                            onScroll={handleContentScroll}
                        >
                            <Table
                                topics={topics}
                                students={students}
                                marks={marks}
                                quarterMarks={quarterMarks}
                                quarter={selectedQuarter + 1}
                                onLessonClick={onLessonClick}
                                isStudent={isStudent}
                                courses={isStudentView ? courses : undefined}
                                isParent={isParent}
                                periodsQuantity={periodsQuantity}
                                onMarkApprove={onMarkApprove}
                                selectedGroup={selectedGroup}
                                onMarkClick={onMarkClick}
                                isPeriodEnd={isPeriodEnd}
                            />
                        </div>
                    ) : (
                        <>
                            <div className={cnTableContainer('empty-img')}>
                                <img src={emptyMark} />
                            </div>
                            <div className={cnTableContainer('empty-mark')}>Нет оценок за этот период</div>
                        </>
                    )}
                </div>
            );
        }
        return (
            <div className={cnTableContainer('wrapper')}>
                {isMobile && (
                    <div className={cnTableContainer('left-overlay', { transparent: scrollLeft === 0 })}></div>
                )}
                <div
                    className={cnTableContainer({
                        overall: true,
                        isEndQuarter: selectedQuarter !== periodsQuantity,
                        isStudent: isStudentView,
                    })}
                    ref={tableRef}
                    onScroll={handleContentScroll}
                >
                    <TableOverall
                        students={students}
                        quarterMarks={approvedPeriodMarks}
                        isStudent={isStudent}
                        courses={isStudentView ? courses : undefined}
                        isParent={isParent}
                        periodsQuantity={periodsQuantity}
                    />
                </div>
            </div>
        );
    }, [
        selectedQuarter,
        periodsQuantity,
        isMobile,
        scrollLeft,
        isStudentView,
        handleContentScroll,
        students,
        approvedPeriodMarks,
        isStudent,
        courses,
        isParent,
        isEmptyMark,
        topics,
        marks,
        quarterMarks,
        onLessonClick,
        onMarkApprove,
        selectedGroup,
        onMarkClick,
        isPeriodEnd,
    ]);

    const tabs: ITabsElement[] = useMemo(
        () =>
            Array.from({ length: periodsQuantity + 1 }, (_, index) => ({
                id: index,
                component: getTable,
                title: getTableTabTitle(index, periodsQuantity),
            })),
        [getTable, periodsQuantity],
    );

    return (
        <Tabs
            elements={tabs}
            selectedElement={selectedQuarter}
            onSelectedElementChange={handleSelectQuarter}
            LoaderComponent={LoaderComponent}
        />
    );
};
