import React, { useCallback, useRef } from 'react';
import { cn } from '@bem-react/classname';
import { useOuterClick } from '@lms-elements/hooks';
import { CloseBlue } from '@lms-elements/icons';

import { PopupProps } from './Popup.types';

import './Popup.scss';

const CnPopup = cn('popup');

export const Popup: React.FC<PopupProps> = ({
    setIsShown,
    popupClassName = '',
    wrapperClassName = '',
    headerClassName = '',
    mainClassName = '',
    footerClassName = '',
    buttonClassName = '',
    headerChild = undefined,
    mainChild = undefined,
    footerChild = undefined,
    isNeedCloseButton,
}) => {
    const ref = useRef<HTMLDivElement>(null);

    const handleButtonCloseClick = useCallback(() => {
        setIsShown(false);
    }, [setIsShown]);

    useOuterClick(ref, setIsShown);

    return (
        <div ref={ref} className={`${CnPopup()} ${popupClassName}`}>
            {isNeedCloseButton && (
                <button className={`${CnPopup('close-button')} ${buttonClassName}`} onClick={handleButtonCloseClick}>
                    <CloseBlue />
                </button>
            )}

            <div className={wrapperClassName}>
                <div className={`${CnPopup('header')} ${headerClassName}`}>{headerChild && headerChild}</div>
                <div id="scroll-bar" className={`${CnPopup('main')} ${mainClassName}`}>
                    {mainChild && mainChild}
                </div>
                <div className={`${CnPopup('foooter')} ${footerClassName}`}>{footerChild && footerChild}</div>
            </div>
        </div>
    );
};
