import { IMark } from '../types';

interface MarksByCourse {
    [courseId: number]: IMark[];
}

export const getMarksByCourse = (marks: IMark[], courses: { id: number }[]): MarksByCourse => {
    return courses.reduce((result, course) => {
        result[course.id] = marks.filter((marks) => marks.subject === course.id);

        return result;
    }, {} as MarksByCourse);
};
