import moment from 'moment';

import { IMark } from '../types';

interface MarkDate {
    date: string;
    title: string;
}

export const getMarksDates = (marks: IMark[]): MarkDate[] => {
    const datesMap = marks.reduce((dates, mark) => {
        if (mark.date && !dates[mark.date]) {
            dates[mark.date] = {
                date: mark.date,
                title: moment(mark.date).format('DD.MM'),
            };
        }

        return dates;
    }, {} as { [date: string]: MarkDate });

    return Object.values(datesMap).sort((a, b) => moment(a.date).diff(b.date));
};
