import { HomeworkItemProps } from '@lms-elements/atomic';

import { ScheduleEvent } from '../ScheduleBoard.types';

export const getTitle = (
    lessons: ScheduleEvent[],
    homeworks: HomeworkItemProps[],
    allCheckboxesOff?: boolean,
    isStudent?: boolean,
    createCalendarLessonLabel: (lessonCount: number) => string = (count) => `${count} занятие(ий)`,
): string => {
    const hasLessons = lessons.length > 0;
    const isEmpty = !hasLessons && homeworks.length === 0;

    if (isEmpty) {
        return isStudent ? 'Занятий и дз нет' : 'Занятий и проверки дз нет';
    }

    if (allCheckboxesOff) {
        return `${createCalendarLessonLabel(lessons.length)} и ${homeworks.length} дз`;
    }

    if (hasLessons) {
        return 'Занятия';
    }

    return 'Занятий нет';
};
