import React, { useCallback, useMemo } from 'react';
import { cn } from '@bem-react/classname';
import { LessonHomeworkItem } from '@lms-elements/atomic';

import { getConferenceTab, getLessonHeight } from '../../utils';

import { TabletScheduleProps } from './DayScheduleItem.types';

const CnCalendarDay = cn('calendarDay');

export const DayScheduleItem: React.FC<TabletScheduleProps> = ({
    lesson,
    isShow,
    isEmpty,
    onLessonHomeworkClick,
    subjectId,
    lessonId,
    isNeedHideLessonButton,
}) => {
    const { data } = lesson;
    const style = useMemo(() => getLessonHeight(lesson), [lesson]);

    const handleLessonClick = useCallback(() => {
        if (data && onLessonHomeworkClick && subjectId && lessonId && data?.courseGroup?.id) {
            const lessonTab = getConferenceTab(data);
            onLessonHomeworkClick(subjectId, lessonId, lessonTab, data.courseGroup.id);
        }
    }, [data, lessonId, onLessonHomeworkClick, subjectId]);

    const handleHomeworkClick = useCallback(() => {
        if (data && onLessonHomeworkClick && subjectId && lessonId && data?.courseGroup?.id) {
            onLessonHomeworkClick(subjectId, lessonId, 'tasks', data.courseGroup.id);
        }
    }, [data, lessonId, onLessonHomeworkClick, subjectId]);

    if (!isEmpty && data) {
        return (
            <li className={CnCalendarDay('lessonsItem', { isEmptyLesson: isEmpty })} style={style}>
                {isShow && (
                    <LessonHomeworkItem
                        {...data}
                        extendedClass={CnCalendarDay('LessonHomeworkItem')}
                        onLessonClick={handleLessonClick}
                        onHomeworkClick={handleHomeworkClick}
                        hideHomeworkStatus
                        isNeedHideLessonButton={isNeedHideLessonButton}
                    />
                )}
            </li>
        );
    }

    return <li className={CnCalendarDay('lessonsItem', { isEmptyLesson: isEmpty })} style={style}></li>;
};
