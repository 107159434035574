import { Student } from '../types/shared.types';

export const getSortedStudents = (students: Student[]): Student[] => {
    return [...students].sort((a, b) => {
        if (a.lastName > b.lastName) {
            return 1;
        }
        if (a.lastName < b.lastName) {
            return -1;
        }
        if (a.firstName > b.firstName) {
            return 1;
        }
        if (a.firstName < b.firstName) {
            return -1;
        }
        return 0;
    });
};
