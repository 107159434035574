import React, { useCallback, useMemo } from 'react';
import { cn } from '@bem-react/classname';
import { DayOnCalendar } from '@lms-elements/atomic';
import { getDaysInMonth, getMonth, getWeekdayNumber } from '@lms-elements/utils';

import { ScheduleWithDisabledItems } from '../ScheduleWithDisabledItems';
import { getHomeworksCount, getLessonsCount, getMeetingsCount } from '../utils';

import { CalendarMonthProps } from './CalendarMonth.types';

import './CalendarMonth.scss';

const CnCalendarMonth = cn('calendarMonth');

export const CalendarMonth: React.FC<CalendarMonthProps> = ({
    date,
    dataMap,
    tasksMap,
    lessonCheck,
    meetingCheck,
    taskCheck,
    isStudent,
    onDayClick,
    createCalendarLessonLabel,
    createTaskLabel,
    createMeetingLabel,
}) => {
    const month = getMonth(date);
    const daysOfMonth = useMemo(() => getDaysInMonth(month), [month]);

    const allCheckboxesOff = useMemo(
        () => !lessonCheck && !meetingCheck && !taskCheck,
        [lessonCheck, meetingCheck, taskCheck],
    );

    const lessonsCount = useMemo(() => getLessonsCount(dataMap), [dataMap]);
    const meetingsCount = useMemo(() => getMeetingsCount(dataMap), [dataMap]);
    const homeworksCount = useMemo(() => (tasksMap ? getHomeworksCount(tasksMap) : 0), [tasksMap]);

    const days = useMemo(() => {
        const blankArray = new Array(daysOfMonth).fill(' ');
        const daysData = blankArray.map((_, index) => {
            const date = index > 8 ? `${month}-${index + 1}` : `${month}-0${index + 1}`;
            const monthDayData = dataMap[date]?.data || [];

            return {
                id: String(index + 1),
                weekday: getWeekdayNumber(date),
                date,
                dayData: {
                    lessonsCount: monthDayData.filter((value) => value.type !== 'meeting').length,
                    meetingsCount: monthDayData.filter((value) => value.type === 'meeting').length,
                },
                taskData: {
                    deadline: tasksMap?.[date]?.data.length ?? 0,
                    assigned: tasksMap?.[date]?.assigned.length ?? 0,
                },
            };
        });

        return daysData;
    }, [daysOfMonth, month, dataMap, tasksMap]);

    const handleDayClick = useCallback(
        (e: React.MouseEvent<HTMLDivElement>) => {
            const { date } = e.currentTarget.dataset as { date: string };
            if (date && onDayClick) {
                onDayClick(date);
            }
        },
        [onDayClick],
    );

    if (allCheckboxesOff) {
        return (
            <div className={CnCalendarMonth()}>
                <ScheduleWithDisabledItems
                    lessonsCount={lessonsCount}
                    meetingsCount={meetingsCount}
                    homeworksCount={homeworksCount}
                    createMeetingLabel={createMeetingLabel}
                    createCalendarLessonLabel={createCalendarLessonLabel}
                />
            </div>
        );
    }

    return (
        <div className={CnCalendarMonth()}>
            {days.map(({ date, ...day }) => (
                <div
                    key={day.id}
                    className={CnCalendarMonth('dayContainer')}
                    style={{ gridColumn: `${day.weekday + 1}` }}
                >
                    <div data-date={date} className={CnCalendarMonth('day')} onClick={handleDayClick}>
                        <DayOnCalendar
                            date={date}
                            {...day.dayData}
                            taskData={day.taskData}
                            isStudent={isStudent}
                            createCalendarLessonLabel={createCalendarLessonLabel}
                            createTaskLabel={createTaskLabel}
                            createMeetingLabel={createMeetingLabel}
                        />
                    </div>
                </div>
            ))}
        </div>
    );
};
