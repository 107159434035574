import * as React from 'react';
interface SVGRProps {
    title?: string;
    titleId?: string;
}

function SvgPlayButton({ title, titleId, ...props }: React.SVGProps<SVGSVGElement> & SVGRProps) {
    return (
        <svg
            width={104}
            height={104}
            viewBox="0 0 104 104"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
            aria-labelledby={titleId}
            {...props}
        >
            {title ? <title id={titleId}>{title}</title> : null}
            <g filter="url(#play-button_svg__filter0_d)">
                <path d="M32 28a4 4 0 014-4h32a4 4 0 014 4v32a4 4 0 01-4 4H36a4 4 0 01-4-4V28z" fill="#0088E9" />
            </g>
            <path
                d="M60 42.268c1.333.77 1.333 2.694 0 3.464L48 52.66c-1.333.77-3-.192-3-1.732V37.072c0-1.54 1.667-2.502 3-1.732l12 6.928z"
                fill="#fff"
            />
            <defs>
                <filter
                    id="play-button_svg__filter0_d"
                    x={0}
                    y={0}
                    width={104}
                    height={104}
                    filterUnits="userSpaceOnUse"
                    colorInterpolationFilters="sRGB"
                >
                    <feFlood floodOpacity={0} result="BackgroundImageFix" />
                    <feColorMatrix
                        in="SourceAlpha"
                        values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
                        result="hardAlpha"
                    />
                    <feOffset dy={8} />
                    <feGaussianBlur stdDeviation={16} />
                    <feColorMatrix values="0 0 0 0 0.0784314 0 0 0 0 0.192157 0 0 0 0 0.498039 0 0 0 0.08 0" />
                    <feBlend in2="BackgroundImageFix" result="effect1_dropShadow" />
                    <feBlend in="SourceGraphic" in2="effect1_dropShadow" result="shape" />
                </filter>
            </defs>
        </svg>
    );
}

const MemoSvgPlayButton = React.memo(SvgPlayButton);
export default MemoSvgPlayButton;
