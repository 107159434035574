import { IQuarterMark, Student } from '../types';

export const getQuarterMarksByStudent = (
    students: Student[],
    marks: IQuarterMark[],
): {
    [student: number]: {
        [quarter: number]: IQuarterMark | undefined;
    };
} => {
    return students.reduce(
        (result, student) => {
            result[student.id] = [0, 1, 2, 3, 4].reduce(
                (resultQuarter, quarter) => {
                    const value = marks.find((mark) => mark.quarter === quarter && mark.student === student.id);
                    resultQuarter[quarter] = value;
                    return resultQuarter;
                },
                {} as {
                    [quarter: number]: IQuarterMark | undefined;
                },
            );
            return result;
        },
        {} as {
            [student: number]: {
                [quarter: number]: IQuarterMark | undefined;
            };
        },
    );
};
