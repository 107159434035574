import React, { useCallback, useEffect, useMemo, useRef, useState } from 'react';
import { cn } from '@bem-react/classname';
import { Mark } from '@lms-elements/atomic';
import { useDragScrollDoubleRef, useWindowResize } from '@lms-elements/hooks';
import { Expand } from '@lms-elements/icons';
import { Popup } from '@lms-elements/popup';

import { DragScrollStateEnum, IQuarterMark, SubjectType } from '../../types';
import { getMarksByCourse, getMarksByStudent, getMarksDates, getQuarterMarksByCourse } from '../../utils';
import { ApprovePopupContent } from '../ApprovePopupContent';

import { ITableProps } from './Table.types';

import './Table.scss';

const cnTable = cn('pt-table');
const cnContainer = cn('pt-container');

const maxWidthShadow = 16;

export const Table: React.FC<ITableProps> = ({
    topics,
    students,
    marks,
    quarter,
    selectedGroup,
    quarterMarks,
    onLessonClick,
    isStudent,
    isPeriodEnd,
    courses,
    isParent,
    periodsQuantity = 4,
    onMarkApprove,
    onMarkClick,
}) => {
    const marksByStudent = useMemo(() => getMarksByStudent(students, topics, marks), [students, topics, marks]);
    const { isMobile } = useWindowResize();

    useEffect(() => {
        update((prev) => !prev);
    }, [quarter]);

    const [, update] = useState(true);

    const handleSetVisible = (key: string) => {
        setMarksByStudentVisible((prev) =>
            prev.map((mark) => (mark.key !== key ? { ...mark } : { ...mark, visible: !mark.visible })),
        );
        setTimeout(() => {
            update((prev) => !prev);
        });
    };

    const markByStudents = useMemo(() => {
        const mark: { key: string; visible: boolean }[] = [];
        topics.forEach((el) =>
            el.lessons.forEach((lesson) =>
                students.forEach((student) => mark.push({ key: `${student.id}${el.id}${lesson.id}`, visible: false })),
            ),
        );
        return mark;
    }, [students, topics]);

    const [marksByStudentVisible, setMarksByStudentVisible] =
        useState<{ key: string; visible: boolean }[]>(markByStudents);

    const filledLessons = useMemo(() => {
        const value: { [lesson: number]: boolean } = {};
        topics.forEach((topic) => {
            topic.lessons.forEach((lesson) => {
                value[lesson.id] = marks.some((mark) => mark.lesson === lesson.id);
            });
        });
        return value;
    }, [topics, marks]);

    const quarterMarksByStudent = useMemo(() => {
        const values: { [student: number]: IQuarterMark | undefined } = {};
        students.forEach((student) => {
            const studentMarks = quarterMarks.find((mark) => mark.student === student.id);
            values[student.id] = studentMarks;
        });
        return values;
    }, [students, quarterMarks]);

    const rightQuarterTitle: string = useMemo(() => {
        const approved = quarterMarks.every((mark) => !!mark.score);
        const periodLabel = periodsQuantity === 4 ? 'Четверть' : periodsQuantity === 3 ? 'Триместр' : 'Семестр';
        return `${quarter} ${periodLabel} ${approved ? '(итог.)' : '(предв.)'}`;
    }, [periodsQuantity, quarter, quarterMarks]);

    const [moveState, setMoveState] = useState(DragScrollStateEnum.notScrolling);
    const [currentPosition, setCurrentPosition] = useState(0);

    const ref = useRef<HTMLDivElement>(null);
    const refSecond = useRef<HTMLDivElement>(null);
    const refPopUp = useRef<HTMLDivElement>(null);

    const refTopics = useRef<HTMLDivElement[]>([]);
    const refLessons = useRef<HTMLDivElement[]>([]);

    useDragScrollDoubleRef(currentPosition, moveState, ref, refSecond, setCurrentPosition, setMoveState);

    useEffect(() => {
        ref.current?.scrollTo({ left: 0 });
        refSecond.current?.scrollTo({ left: 0 });
    }, [quarter]);

    const handleMouseDown = useCallback(() => {
        setMoveState(DragScrollStateEnum.scrollStart);
    }, []);

    const handleMouseUp = useCallback(() => {
        if (moveState === DragScrollStateEnum.scrollStart) {
            setMoveState(DragScrollStateEnum.scrollEnd);
        }
    }, [moveState]);

    const handleMobileScroll = useCallback(() => {
        if (isMobile) {
            setCurrentPosition(ref.current?.scrollLeft || 0);
            refSecond.current?.scrollTo({
                left: ref.current?.scrollLeft,
            });
        }
    }, [isMobile]);

    const [scrollDiff, setScrollDiff] = useState(maxWidthShadow + 1);

    useEffect(() => {
        if (ref.current) {
            setScrollDiff(ref.current?.scrollWidth - ref.current?.scrollLeft - ref.current?.clientWidth);
        }
    }, [ref.current?.scrollLeft]);

    const shadowParams = useMemo(() => {
        if (scrollDiff < maxWidthShadow) {
            return {
                boxShadow: `-${scrollDiff}px ${Math.floor((scrollDiff * 12) / 16)}px ${Math.floor(
                    (scrollDiff * 22) / 16,
                )}px 0px #14317f14`,
            };
        }
        return {};
    }, [scrollDiff]);

    const mobileQuarterMarks = useMemo(() => {
        if (isMobile) {
            return { display: `${scrollDiff - 59 > maxWidthShadow ? 'none' : 'block'}` };
        }
        return {};
    }, [isMobile, scrollDiff]);

    const makeHandlerLessonClick = useCallback(
        (lesson: number) => () => {
            if (onLessonClick) {
                onLessonClick(lesson);
            }
        },
        [onLessonClick],
    );

    const makeHandlerMarkClick = useCallback(
        (params: { lesson: number; groupId?: number; subject?: number; type?: string }) => () => {
            if (onMarkClick) {
                onMarkClick({
                    lesson: params.lesson,
                    groupId: params.groupId,
                    subject: params.subject,
                    type: params.type,
                });
            }
        },
        [onMarkClick],
    );

    const isStudentView = useMemo(() => isStudent || isParent, [isParent, isStudent]);

    const quarterMarksByCourse = useMemo(() => getQuarterMarksByCourse(quarterMarks, courses), [courses, quarterMarks]);
    const dates = useMemo(() => getMarksDates(marks), [marks]);

    const marksByCourse = useMemo(
        () => (isStudentView && courses ? getMarksByCourse(marks, courses) : []),
        [isStudentView, marks, courses],
    );

    const handleSetVisibleByCourse = (key: string) => {
        setMarksByCourseVisible((prev) =>
            prev.map((mark) => (mark.key !== key ? { ...mark } : { ...mark, visible: !mark.visible })),
        );
        setTimeout(() => {
            update((prev) => !prev);
        });
    };

    const markByCourse = useMemo(() => {
        const mark: { key: string; visible: boolean }[] = [];
        courses?.forEach((course) =>
            dates.forEach((date) => mark.push({ key: `${course.id}${date.date}`, visible: false })),
        );
        return mark;
    }, [courses, dates]);

    const [marksByCourseVisible, setMarksByCourseVisible] = useState<{ key: string; visible: boolean }[]>(markByCourse);

    const [showTooltip, setShowTooltip] = useState(true);

    useEffect(() => {
        setShowTooltip(true);
    }, [showTooltip]);

    const [isShownPopup, setIsShownPopup] = useState(false);
    const [selectedMark, setSelectedMark] = useState(
        quarterMarks.findIndex((el) => el.isTest === true) !== -1 ? false : 5,
    );
    const [selectedMarkId, setSelectedMarkId] = useState<number>();
    const [popupPosition, setPopupPosition] = useState({ top: -9999, left: -9999 });

    const handlePeriodMarkClick = useCallback((e: React.MouseEvent<HTMLDivElement>) => {
        const markElement = e.currentTarget;
        const dataset = markElement.dataset as { approved?: string; id?: string; score?: string | boolean };
        const approved = dataset.approved === 'true';
        const markId = Number(dataset.id);
        const score = typeof dataset.score !== 'boolean' ? Number(dataset.score) : dataset.score;

        if (!approved && !isNaN(markId)) {
            const { x, y } = markElement.getBoundingClientRect();

            setIsShownPopup(!approved);
            setSelectedMarkId(markId);
            setSelectedMark(score);
            setPopupPosition({ top: y, left: x });
        }
    }, []);

    const handleApproveMark = useCallback(() => {
        if (selectedMarkId && onMarkApprove) {
            onMarkApprove(selectedMarkId, selectedMark);
        }

        setIsShownPopup(false);
    }, [onMarkApprove, selectedMark, selectedMarkId]);

    const handleCancelClick = useCallback(() => {
        setIsShownPopup(false);
    }, []);

    useEffect(() => {
        if (isShownPopup) {
            refPopUp.current?.scrollIntoView({ block: 'center', behavior: 'smooth' });
        }
    }, [isShownPopup]);

    return (
        <>
            <div
                className={cnTable({ sticky: true, isStudent: isStudentView })}
                ref={refSecond}
                onMouseDown={handleMouseDown}
                onMouseUp={handleMouseUp}
                onTouchStart={handleMouseDown}
                onTouchEnd={handleMouseUp}
            >
                <>
                    {!isStudentView &&
                        refTopics.current.length !== 0 &&
                        topics.map((topic, indexTopic) => (
                            <div
                                className={cnContainer()}
                                key={`Topic${topic.id}`}
                                style={{
                                    width: refTopics.current[indexTopic]?.offsetWidth ?? 0,
                                }}
                            >
                                <div className={cnTable('header')} title={topic.title}>
                                    <span>{topic.title}</span>
                                </div>
                                <div className={cnTable('lessons')}>
                                    {topic.lessons.map((lesson, indexLesson) => (
                                        <div
                                            className={cnTable('lessons-marks')}
                                            key={`Lesson${lesson.id}`}
                                            style={{
                                                width:
                                                    refLessons.current[Number(`${indexTopic}${indexLesson}`)]
                                                        ?.offsetWidth ?? 0,
                                            }}
                                        >
                                            <div
                                                className={cnTable('lesson-header', {
                                                    empty: !filledLessons[lesson.id],
                                                })}
                                                onClick={makeHandlerLessonClick(lesson.id)}
                                                title={lesson.title}
                                            >
                                                <span>{lesson.title}</span>
                                            </div>
                                        </div>
                                    ))}
                                </div>
                            </div>
                        ))}
                    {isStudentView && (
                        <div className={cnContainer({ isStudent: isStudentView })}>
                            <div className={cnTable('dates-student', { isStudent: isStudentView })}>
                                {dates.map(({ date, title }, index) => (
                                    <div
                                        className={cnTable('dates-marks')}
                                        key={date}
                                        style={{
                                            width: refTopics.current[index]?.offsetWidth ?? 0,
                                        }}
                                    >
                                        <div className={cnTable('date-header')}>{title}</div>
                                    </div>
                                ))}
                            </div>
                        </div>
                    )}
                </>
            </div>

            <div
                className={cnTable({ isStudent: isStudentView, isStudentTable: true })}
                ref={ref}
                onMouseDown={handleMouseDown}
                onMouseUp={handleMouseUp}
                onTouchStart={handleMouseDown}
                onTouchEnd={handleMouseUp}
                onScroll={handleMobileScroll}
            >
                {isStudentView && (
                    <div className={cnContainer({ isStudent: isStudentView })}>
                        <div className={cnTable('dates', { isStudent: isStudentView })}>
                            {dates.map(({ date }, index) => (
                                <div
                                    className={cnTable('dates-marks')}
                                    key={`${date}`}
                                    ref={(el) =>
                                        (refTopics.current[index] = el ?? ({ offsetWidth: 0 } as HTMLDivElement))
                                    }
                                >
                                    {Object.values(SubjectType).map((type, index) => {
                                        return (
                                            <>
                                                {index > 0 && courses?.map((el) => el.type).includes(type) && (
                                                    <div
                                                        className={cnTable('marks', { empty: true })}
                                                        key={`Course-${type}Date${date}`}
                                                    />
                                                )}
                                                {courses
                                                    ?.filter((course) => course.type === type)
                                                    .map((course) => (
                                                        <div
                                                            className={cnTable('marks')}
                                                            key={`Course${course.id}Date${date}`}
                                                        >
                                                            {marksByCourse[course.id].filter(
                                                                (mark) => mark.date === date,
                                                            ).length < 4
                                                                ? marksByCourse[course.id]
                                                                      .filter((mark) => mark.date === date)
                                                                      .map((mark) => (
                                                                          <div
                                                                              key={`Mark${mark.id}`}
                                                                              onClick={makeHandlerMarkClick({
                                                                                  lesson: mark.lesson,
                                                                                  groupId: course.groupId,
                                                                                  subject: course.subject,
                                                                                  type: mark.lessonType,
                                                                              })}
                                                                          >
                                                                              <Mark
                                                                                  markId={String(mark.id)}
                                                                                  value={mark.score}
                                                                                  comment={mark.comment}
                                                                                  key={`Mark${mark.id}`}
                                                                                  hoverable={
                                                                                      showTooltip &&
                                                                                      moveState !==
                                                                                          DragScrollStateEnum.scrollStart
                                                                                  }
                                                                                  type={mark.type}
                                                                                  weight={mark.weight}
                                                                                  date={mark.date}
                                                                                  withDetailedTooltip={
                                                                                      !isParent ||
                                                                                      !mark.withAttendanceTooltip
                                                                                  }
                                                                                  withAttendanceTooltip={
                                                                                      isParent &&
                                                                                      mark.withAttendanceTooltip
                                                                                  }
                                                                                  attendenceData={
                                                                                      isParent
                                                                                          ? mark.attendenceData
                                                                                          : undefined
                                                                                  }
                                                                                  commentLabel={mark.commentLabel}
                                                                                  filesLabel={mark.filesLabel}
                                                                                  files={mark.files}
                                                                                  needTrimComment={mark.needTrimComment}
                                                                                  maxCommentLength={
                                                                                      mark.maxCommentLength
                                                                                  }
                                                                              />
                                                                          </div>
                                                                      ))
                                                                : marksByCourseVisible.find(
                                                                      (el) => el.key === `${course.id}${date}`,
                                                                  )?.visible === false
                                                                ? marksByCourse[course.id]
                                                                      .filter((mark) => mark.date === date)
                                                                      .slice(0, 3)
                                                                      .map((mark) => (
                                                                          <div
                                                                              key={`Mark${mark.id}`}
                                                                              onClick={makeHandlerMarkClick({
                                                                                  lesson: mark.lesson,
                                                                                  groupId: course.groupId,
                                                                                  subject: course.subject,
                                                                                  type: mark.lessonType,
                                                                              })}
                                                                          >
                                                                              <Mark
                                                                                  markId={String(mark.id)}
                                                                                  value={mark.score}
                                                                                  comment={mark.comment}
                                                                                  key={`Mark${mark.id}`}
                                                                                  hoverable={
                                                                                      showTooltip &&
                                                                                      moveState !==
                                                                                          DragScrollStateEnum.scrollStart
                                                                                  }
                                                                                  type={mark.type}
                                                                                  weight={mark.weight}
                                                                                  date={mark.date}
                                                                                  withDetailedTooltip={
                                                                                      !isParent ||
                                                                                      !mark.withAttendanceTooltip
                                                                                  }
                                                                                  withAttendanceTooltip={
                                                                                      isParent &&
                                                                                      mark.withAttendanceTooltip
                                                                                  }
                                                                                  attendenceData={
                                                                                      isParent
                                                                                          ? mark.attendenceData
                                                                                          : undefined
                                                                                  }
                                                                                  commentLabel={mark.commentLabel}
                                                                                  filesLabel={mark.filesLabel}
                                                                                  files={mark.files}
                                                                                  needTrimComment={mark.needTrimComment}
                                                                                  maxCommentLength={
                                                                                      mark.maxCommentLength
                                                                                  }
                                                                              />
                                                                          </div>
                                                                      ))
                                                                : marksByCourse[course.id]
                                                                      .filter((mark) => mark.date === date)
                                                                      .map((mark) => (
                                                                          <div
                                                                              key={`Mark${mark.id}`}
                                                                              onClick={makeHandlerMarkClick({
                                                                                  lesson: mark.lesson,
                                                                                  groupId: course.groupId,
                                                                                  subject: course.subject,
                                                                                  type: mark.lessonType,
                                                                              })}
                                                                          >
                                                                              <Mark
                                                                                  markId={String(mark.id)}
                                                                                  value={mark.score}
                                                                                  comment={mark.comment}
                                                                                  key={`Mark${mark.id}`}
                                                                                  hoverable={
                                                                                      showTooltip &&
                                                                                      moveState !==
                                                                                          DragScrollStateEnum.scrollStart
                                                                                  }
                                                                                  type={mark.type}
                                                                                  weight={mark.weight}
                                                                                  date={mark.date}
                                                                                  withDetailedTooltip={
                                                                                      !isParent ||
                                                                                      !mark.withAttendanceTooltip
                                                                                  }
                                                                                  withAttendanceTooltip={
                                                                                      isParent &&
                                                                                      mark.withAttendanceTooltip
                                                                                  }
                                                                                  attendenceData={
                                                                                      isParent
                                                                                          ? mark.attendenceData
                                                                                          : undefined
                                                                                  }
                                                                                  commentLabel={mark.commentLabel}
                                                                                  filesLabel={mark.filesLabel}
                                                                                  files={mark.files}
                                                                                  needTrimComment={mark.needTrimComment}
                                                                                  maxCommentLength={
                                                                                      mark.maxCommentLength
                                                                                  }
                                                                              />
                                                                          </div>
                                                                      ))}
                                                            {marksByCourse[course.id].filter(
                                                                (mark) => mark.date === date,
                                                            ).length > 3 && (
                                                                <Expand
                                                                    className={cnTable('loadMarksStudents', {
                                                                        isVisible:
                                                                            marksByCourseVisible.find(
                                                                                (el) =>
                                                                                    el.key === `${course.id}${date}`,
                                                                            )?.visible === true,
                                                                    })}
                                                                    onClick={() =>
                                                                        handleSetVisibleByCourse(`${course.id}${date}`)
                                                                    }
                                                                />
                                                            )}
                                                        </div>
                                                    ))}
                                            </>
                                        );
                                    })}
                                </div>
                            ))}
                        </div>
                    </div>
                )}
                {!isStudentView &&
                    topics.map((topic, indexTopic) => (
                        <div
                            className={cnContainer()}
                            key={`Topic${topic.id}`}
                            ref={(el) => (refTopics.current[indexTopic] = el ?? ({} as HTMLDivElement))}
                        >
                            <div className={cnTable('lessons')}>
                                {topic.lessons.map((lesson, indexLesson) => (
                                    <div
                                        className={cnTable('lessons-marks')}
                                        key={`Lesson${lesson.id}`}
                                        ref={(el) =>
                                            (refLessons.current[Number(`${indexTopic}${indexLesson}`)] =
                                                el ?? ({} as HTMLDivElement))
                                        }
                                    >
                                        {students.map((student) => (
                                            <div
                                                className={cnTable('marks')}
                                                key={`MarksStudent${student.id}Lesson${lesson.id}`}
                                            >
                                                {marksByStudent[student.id][topic.id][lesson.id].length < 4
                                                    ? marksByStudent[student.id][topic.id][lesson.id].map((mark) => (
                                                          <div
                                                              key={`Mark${mark.id}`}
                                                              onClick={makeHandlerMarkClick({
                                                                  lesson: lesson.id,
                                                                  groupId: selectedGroup,
                                                                  subject: mark.subject,
                                                                  type: mark.lessonType,
                                                              })}
                                                          >
                                                              <Mark
                                                                  markId={String(mark.id)}
                                                                  value={mark.score}
                                                                  comment={mark.comment}
                                                                  key={`Mark${mark.id}`}
                                                                  hoverable={
                                                                      moveState !== DragScrollStateEnum.scrollStart
                                                                  }
                                                                  type={mark.type}
                                                                  weight={mark.weight}
                                                                  date={mark.date}
                                                                  commentLabel={mark.commentLabel}
                                                                  filesLabel={mark.filesLabel}
                                                                  files={mark.files}
                                                                  needTrimComment={mark.needTrimComment}
                                                                  withDetailedTooltip
                                                              />
                                                          </div>
                                                      ))
                                                    : marksByStudentVisible.find(
                                                          (el) => el.key === `${student.id}${topic.id}${lesson.id}`,
                                                      )?.visible === false
                                                    ? marksByStudent[student.id][topic.id][lesson.id]
                                                          .slice(0, 3)
                                                          .map((mark) => (
                                                              <div
                                                                  key={`Mark${mark.id}`}
                                                                  onClick={makeHandlerMarkClick({
                                                                      lesson: lesson.id,
                                                                      groupId: selectedGroup,
                                                                      subject: mark.subject,
                                                                      type: mark.lessonType,
                                                                  })}
                                                              >
                                                                  <Mark
                                                                      markId={String(mark.id)}
                                                                      value={mark.score}
                                                                      comment={mark.comment}
                                                                      key={`Mark${mark.id}`}
                                                                      hoverable={
                                                                          moveState !== DragScrollStateEnum.scrollStart
                                                                      }
                                                                      type={mark.type}
                                                                      weight={mark.weight}
                                                                      date={mark.date}
                                                                      commentLabel={mark.commentLabel}
                                                                      filesLabel={mark.filesLabel}
                                                                      files={mark.files}
                                                                      withDetailedTooltip
                                                                      needTrimComment={mark.needTrimComment}
                                                                  />
                                                              </div>
                                                          ))
                                                    : marksByStudent[student.id][topic.id][lesson.id].map((mark) => (
                                                          <div
                                                              key={`Mark${mark.id}`}
                                                              onClick={makeHandlerMarkClick({
                                                                  lesson: lesson.id,
                                                                  groupId: selectedGroup,
                                                                  subject: mark.subject,
                                                                  type: mark.lessonType,
                                                              })}
                                                          >
                                                              <Mark
                                                                  markId={String(mark.id)}
                                                                  value={mark.score}
                                                                  comment={mark.comment}
                                                                  key={`Mark${mark.id}`}
                                                                  hoverable={
                                                                      moveState !== DragScrollStateEnum.scrollStart
                                                                  }
                                                                  type={mark.type}
                                                                  weight={mark.weight}
                                                                  date={mark.date}
                                                                  commentLabel={mark.commentLabel}
                                                                  filesLabel={mark.filesLabel}
                                                                  files={mark.files}
                                                                  withDetailedTooltip
                                                                  needTrimComment={mark.needTrimComment}
                                                              />
                                                          </div>
                                                      ))}
                                                {marksByStudent[student.id][topic.id][lesson.id].length > 3 && (
                                                    <Expand
                                                        className={cnTable('loadMarks', {
                                                            isVisible:
                                                                marksByStudentVisible.find(
                                                                    (el) =>
                                                                        el.key ===
                                                                        `${student.id}${topic.id}${lesson.id}`,
                                                                )?.visible === true,
                                                        })}
                                                        onClick={() =>
                                                            handleSetVisible(`${student.id}${topic.id}${lesson.id}`)
                                                        }
                                                    />
                                                )}
                                            </div>
                                        ))}
                                    </div>
                                ))}
                            </div>
                        </div>
                    ))}
            </div>
            {selectedGroup !== undefined && (
                <div
                    className={cnTable('quarter-marks', { isStudent: isStudentView })}
                    style={{ ...shadowParams, ...mobileQuarterMarks }}
                >
                    <div className={cnTable('quarter-header', { isStudent: isStudentView, sticky: !isStudentView })}>
                        <span className={cnTable('quarter-header-label')}>{rightQuarterTitle}</span>
                    </div>
                    {isStudentView &&
                        Object.values(SubjectType).map((type, index) => {
                            return (
                                <>
                                    {index > 0 && courses?.map((el) => el.type).includes(type) && (
                                        <div className={cnTable('marks')} key={`QuarterMark-${type}`} />
                                    )}
                                    {courses
                                        ?.filter((course) => course.type === type)
                                        .map((course) => (
                                            <div className={cnTable('marks')} key={`QuarterMark${course.id}`}>
                                                {quarterMarksByCourse[course.id] && (
                                                    <Mark
                                                        markId={String(quarterMarksByCourse[course.id]?.id)}
                                                        value={
                                                            !quarterMarksByCourse[course.id]?.isTest
                                                                ? quarterMarksByCourse[course.id]?.score
                                                                    ? quarterMarksByCourse[course.id]?.score
                                                                    : Math.round(
                                                                          Number(
                                                                              quarterMarksByCourse[course.id]?.average,
                                                                          ),
                                                                      )
                                                                : undefined
                                                        }
                                                        approved={
                                                            !quarterMarksByCourse[course.id]?.isTest
                                                                ? !!quarterMarksByCourse[course.id]?.score
                                                                : !!quarterMarksByCourse[course.id]?.testApproved
                                                        }
                                                        average={quarterMarksByCourse[course.id]?.average}
                                                        isPeriodMark
                                                        needHideWeight
                                                        isTestPassed={
                                                            quarterMarksByCourse[course.id]?.isTest
                                                                ? Number(quarterMarksByCourse[course.id]?.average) >=
                                                                  2.5
                                                                    ? true
                                                                    : false
                                                                : undefined
                                                        }
                                                        hoverable
                                                    />
                                                )}
                                            </div>
                                        ))}
                                </>
                            );
                        })}
                    {!isStudentView && (
                        <>
                            {students.map((student) => (
                                <div
                                    key={`QuarterMark${student.id}`}
                                    className={cnTable('marks')}
                                    data-approved={quarterMarksByStudent[student.id]?.score}
                                    data-id={quarterMarksByStudent[student.id]?.id}
                                    data-score={quarterMarksByStudent[student.id]?.score}
                                    onClick={
                                        !quarterMarksByStudent[student.id]?.score &&
                                        !quarterMarksByStudent[student.id]?.testApproved &&
                                        isPeriodEnd
                                            ? handlePeriodMarkClick
                                            : undefined
                                    }
                                >
                                    {quarterMarksByStudent[student.id] && (
                                        <Mark
                                            markId={String(quarterMarksByStudent[student.id]?.id)}
                                            value={
                                                !quarterMarksByStudent[student.id]?.isTest
                                                    ? quarterMarksByStudent[student.id]?.score
                                                        ? quarterMarksByStudent[student.id]?.score
                                                        : Math.round(Number(quarterMarksByStudent[student.id]?.average))
                                                    : undefined
                                            }
                                            approved={
                                                !quarterMarksByStudent[student.id]?.isTest
                                                    ? !!quarterMarksByStudent[student.id]?.score
                                                    : !!quarterMarksByStudent[student.id]?.testApproved
                                            }
                                            average={quarterMarksByStudent[student.id]?.average}
                                            isPeriodMark
                                            needHideWeight
                                            isTestPassed={
                                                quarterMarksByStudent[student.id]?.isTest
                                                    ? Number(quarterMarksByStudent[student.id]?.average) > 3
                                                        ? true
                                                        : false
                                                    : undefined
                                            }
                                            hoverable
                                        />
                                    )}
                                </div>
                            ))}
                        </>
                    )}
                </div>
            )}

            {isShownPopup && (
                <div className={cnTable('PopUp')}>
                    <div className={cnTable('popup-layout')}>
                        <div className={cnTable('popup-wrapper')} style={popupPosition} ref={refPopUp}>
                            <Popup
                                popupClassName={cnTable('popup')}
                                setIsShown={setIsShownPopup}
                                mainChild={
                                    <ApprovePopupContent
                                        selectedMark={
                                            quarterMarks.findIndex((el) => el.isTest === true) !== -1
                                                ? selectedMark
                                                : Number(selectedMark)
                                        }
                                        isTestMark={quarterMarks.findIndex((el) => el.isTest === true) !== -1}
                                        setSelectedMark={setSelectedMark}
                                        onSave={handleApproveMark}
                                        onCancel={handleCancelClick}
                                    />
                                }
                            />
                        </div>
                    </div>
                </div>
            )}
        </>
    );
};
