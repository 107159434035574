import React, { useCallback } from 'react';
import { cn } from '@bem-react/classname';

import { getConferenceTab } from '../../utils';

import { LessonHomeworkWrapper } from './LessonHomeworkWrapper';
import { TabletDayScheduleItemProps } from './TabletDayScheduleItem.types';

const CnCalendarDay = cn('calendarDay');

export const TabletDayScheduleItem: React.FC<TabletDayScheduleItemProps> = ({
    itemData,
    time,
    meetingCheck,
    lessonCheck,
    isNeedHideLessonButton,
    onLessonHomeworkClick,
}) => {
    const handleLessonHomeworkClick = useCallback(
        (subjectId: number, conferenceId: number, tab: string, groupId?: number) => {
            if (onLessonHomeworkClick && subjectId && conferenceId && groupId) {
                onLessonHomeworkClick(subjectId, conferenceId, tab, groupId);
            }
        },
        [onLessonHomeworkClick],
    );

    return (
        <li>
            <div className={CnCalendarDay('time', { isTablet: true })}>{time}</div>
            <div className={CnCalendarDay('lessonsItem', { isTablet: true })}>
                {itemData.map((lesson) => {
                    const isShow =
                        (lesson.type === 'meeting' && meetingCheck) || (lesson.type !== 'meeting' && lessonCheck);
                    const lessonTab = getConferenceTab(lesson);
                    return (
                        <div key={lesson.id}>
                            {isShow && (
                                <LessonHomeworkWrapper
                                    lesson={lesson}
                                    subjectId={lesson?.subject?.id ?? 0}
                                    lessonId={lesson?.lesson?.id ?? 0}
                                    lessonTab={lessonTab}
                                    groupId={lesson?.courseGroup?.id ?? 0}
                                    onLessonHomeworkClick={handleLessonHomeworkClick}
                                    isNeedHideLessonButton={isNeedHideLessonButton}
                                />
                            )}
                        </div>
                    );
                })}
            </div>
        </li>
    );
};
