import { HomeworkItemProps } from '@lms-elements/atomic';

import { HomeworkObject } from '../ScheduleBoard.types';

export const getHomeworksCount = (homeworksData: HomeworkObject): number => {
    return Object.values(homeworksData).reduce(
        (result, taskData) => [...result, ...taskData.data],
        [] as HomeworkItemProps[],
    ).length;
};
