import { HomeworkItemProps } from '@lms-elements/atomic';
import {
    checkIsDateBeetween,
    checkIsEquel,
    convertDateToHtmlDateString,
    getDiffBetweenDates,
    getStartAndEndOfStudyDay,
} from '@lms-elements/utils';

import { DeferredTask, Lesson, ScheduleEvent } from '../ScheduleBoard.types';

import { createEmptyLesson } from './createEmptyLesson';
import { normalizeLessonsData } from './normalizeLessonsData';

interface CreateRenderLessonsParams {
    lessons: ScheduleEvent[];
    tasks: HomeworkItemProps[];
    start: number;
    end: number;
}

interface CreateRenderLessonsResult {
    lessons: Lesson[];
    deferredTasks: DeferredTask[];
}

export const createRenderLessonsArray = (params: CreateRenderLessonsParams): CreateRenderLessonsResult => {
    const { lessons, tasks, start, end } = params;

    const { lessons: lessonsWithTask, deferredTasks } = normalizeLessonsData(lessons, tasks);

    let renderLessons: Lesson[] = [];
    if (lessonsWithTask.length) {
        const date = convertDateToHtmlDateString(lessons[0].date);
        renderLessons = lessonsWithTask.reduce((result, lesson, index) => {
            const { startOfDay } = getStartAndEndOfStudyDay(date, start, end);
            const lastElement = result[result.length - 1];

            switch (true) {
                case index === 0 && !checkIsEquel(lesson.datetimeStart, startOfDay):
                    result[0] = createEmptyLesson(startOfDay, lesson.datetimeStart);
                    break;
                case lastElement && !checkIsDateBeetween(lesson.datetimeStart, lastElement):
                    result.push(createEmptyLesson(lastElement.endTime, lesson.datetimeStart));
                    break;
                default:
                    break;
            }

            result.push({
                type: lesson.type,
                startTime: lesson.datetimeStart,
                endTime: lesson.datetimeEnd,
                data: {
                    ...lesson,
                    date,
                },
            });

            return result;
        }, [] as Lesson[]);

        const { endOfDay } = getStartAndEndOfStudyDay(date, start, end);
        if (getDiffBetweenDates(renderLessons[renderLessons.length - 1].endTime, endOfDay) < 0) {
            renderLessons.push(createEmptyLesson(renderLessons[renderLessons.length - 1].endTime, endOfDay));
        }
    }

    return {
        deferredTasks,
        lessons: renderLessons,
    };
};
