import moment, { Moment } from 'moment';

/**
 *
 * @param first first date
 * @param second second date
 * @param unitOfTime result unit of measurement
 * @returns
 */
export const getDiffBetweenDates = (
    first: Moment | Date | string,
    second: Moment | Date | string,
    unitOfTime: moment.unitOfTime.Diff = 'ms',
): number => {
    return moment(first).diff(second, unitOfTime);
};
