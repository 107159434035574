import React, { useMemo } from 'react';
import { cn } from '@bem-react/classname';
import { Mark } from '@lms-elements/atomic';

import { SubjectType } from '../../types';
import { getOverallHeaderLabel, getOverallQuarterMarksByCourse, getQuarterMarksByStudent } from '../../utils';

import { ITableOverallProps } from './TableOverall.type';

import './TableOverall.scss';

const cnTableOverall = cn('performance-table-overall');

export const TableOverall: React.FC<ITableOverallProps> = ({
    students,
    quarterMarks,
    isStudent,
    isParent,
    courses,
    periodsQuantity = 4,
}) => {
    const quarterMarksByStudent = useMemo(
        () => getQuarterMarksByStudent(students, quarterMarks),
        [students, quarterMarks],
    );

    const isStudentView = useMemo(() => isStudent || isParent, [isParent, isStudent]);

    const quarterMarksByCourse = useMemo(() => {
        if (isStudentView && courses?.length) {
            return getOverallQuarterMarksByCourse(courses, quarterMarks);
        }

        return {};
    }, [courses, isStudentView, quarterMarks]);

    if (isStudentView) {
        return (
            <div className={cnTableOverall({ isStudent: isStudentView })}>
                {Array.from({ length: periodsQuantity + 1 }, (_, period) => (
                    <div className={cnTableOverall('marks', { isStudent: isStudentView })} key={`Quarter${period}`}>
                        <div className={cnTableOverall('header', { isStudent: isStudentView, sticky: true })}>
                            <span className={cnTableOverall('header-label')}>
                                {getOverallHeaderLabel(period, periodsQuantity)}
                            </span>
                        </div>
                        {Object.values(SubjectType).map((type, index) => {
                            return (
                                <>
                                    {index > 0 && courses?.map((el) => el.type).includes(type) && (
                                        <div
                                            className={cnTableOverall('mark', {
                                                empty: true,
                                                last: period === periodsQuantity,
                                            })}
                                            key={type}
                                        />
                                    )}
                                    {courses
                                        ?.filter((course) => course.type === type)
                                        .map((course) => (
                                            <div
                                                className={cnTableOverall('mark')}
                                                key={`Course${course.id}Quarter${period}`}
                                            >
                                                {quarterMarksByCourse[course.id] &&
                                                    quarterMarksByCourse[course.id][period] && (
                                                        <Mark
                                                            markId={String(quarterMarksByCourse[course.id][period]?.id)}
                                                            value={
                                                                quarterMarksByCourse[course.id][period]?.isTest
                                                                    ? quarterMarksByCourse[course.id][period]?.score
                                                                    : undefined
                                                            }
                                                            approved={
                                                                !quarterMarksByCourse[course.id][period]?.isTest
                                                                    ? !!quarterMarksByCourse[course.id][period]?.score
                                                                    : !!quarterMarksByCourse[course.id][period]
                                                                          ?.testApproved
                                                            }
                                                            needHideWeight
                                                            isTestPassed={
                                                                quarterMarksByCourse[course.id][period]?.isTest
                                                                    ? Number(
                                                                          quarterMarksByCourse[course.id][period]
                                                                              ?.average,
                                                                      ) > 3
                                                                        ? true
                                                                        : false
                                                                    : undefined
                                                            }
                                                        />
                                                    )}
                                            </div>
                                        ))}
                                </>
                            );
                        })}
                    </div>
                ))}
            </div>
        );
    }

    return (
        <div className={cnTableOverall()}>
            {Array.from({ length: periodsQuantity + 1 }, (_, period) => (
                <div className={cnTableOverall('marks')} key={`Quarter${period}`}>
                    <div
                        className={cnTableOverall('header', { sticky: true })}
                        title={getOverallHeaderLabel(period, periodsQuantity)}
                    >
                        <span className={cnTableOverall('header-label')}>
                            {getOverallHeaderLabel(period, periodsQuantity)}
                        </span>
                    </div>
                    {students.map((student) => (
                        <div className={cnTableOverall('mark')} key={`Student${student.id}Quarter${period}`}>
                            {quarterMarksByStudent[student.id][period] && (
                                <Mark
                                    markId={String(quarterMarksByStudent[student.id][period]?.id)}
                                    value={
                                        !quarterMarksByStudent[student.id][period]?.isTest
                                            ? quarterMarksByStudent[student.id][period]?.score
                                            : undefined
                                    }
                                    approved={
                                        !quarterMarksByStudent[student.id][period]?.isTest
                                            ? !!quarterMarksByStudent[student.id][period]?.score
                                            : !!quarterMarksByStudent[student.id][period]?.testApproved
                                    }
                                    needHideWeight
                                    isTestPassed={
                                        quarterMarksByStudent[student.id][period]?.isTest
                                            ? Number(quarterMarksByStudent[student.id][period]?.average) > 3
                                                ? true
                                                : false
                                            : undefined
                                    }
                                />
                            )}
                        </div>
                    ))}
                </div>
            ))}
        </div>
    );
};
