export const getTableTabTitle = (index: number, periodsQuantity: number): string => {
    if (index === periodsQuantity) {
        return 'cводная';
    }

    if (index === 0) {
        switch (periodsQuantity) {
            case 4:
                return `${index + 1} четверть`;
            case 3:
                return `${index + 1} триместр`;
            case 2:
                return `${index + 1} семестр`;
            default:
                return `${index + 1} четверть`;
        }
    }

    return String(index + 1);
};
