import { getDateComponents } from '@lms-elements/utils';

import { ScheduleEvent } from '../ScheduleBoard.types';

export const getStartAndEndTime = (lessons: ScheduleEvent[]): [number, number] => {
    const result = [0, 0] as [number, number];
    if (lessons.length) {
        const startTime = getDateComponents(lessons[0].datetimeStart).hours;

        const endDate = lessons.length > 1 ? lessons[lessons.length - 1].datetimeStart : lessons[0].datetimeEnd;
        const endTime = getDateComponents(endDate).hours;

        if (result[0] === 0 || result[0] > startTime) {
            result[0] = startTime;
        }

        if (result[1] === 0 || result[1] < endTime) {
            result[1] = endTime;
        }

        if (getDateComponents(lessons[0].datetimeStart).date < getDateComponents(endDate).date) {
            result[1] = 24;
        }
    }

    return result;
};
