import React, { useMemo } from 'react';
import { cn } from '@bem-react/classname';

import { createRenderLessonsArray } from '../../utils';
import { WeekScheduleListItem } from '../WeekScheduleListItem';

import { WeekScheduleListProps } from './WeekScheduleList.types';

const CnCalendarWeek = cn('calendarWeek');

export const WeekScheduleList: React.FC<WeekScheduleListProps> = ({
    scheduleData,
    lessonCheck,
    meetingCheck,
    startTime,
    endTime,
    onScheduleItemClick,
}) => {
    const renderData = useMemo(() => {
        return scheduleData.map((day) => ({
            id: day.id,
            data: createRenderLessonsArray({
                lessons: day.data,
                tasks: [],
                start: startTime,
                end: endTime,
            }),
        }));
    }, [endTime, scheduleData, startTime]);

    return (
        <div className={CnCalendarWeek('schedule')}>
            {renderData.map(({ id, data }) => (
                <div key={id} className={CnCalendarWeek('dayContainer')}>
                    <ul className={CnCalendarWeek('lessonsList')}>
                        {data.lessons.map((scheduleItemData, index) => {
                            const { type, data } = scheduleItemData;
                            const isEmptyLesson = type === 'empty';
                            const isShow =
                                (data?.type === 'meeting' && meetingCheck) || (data?.type !== 'meeting' && lessonCheck);
                            return (
                                <WeekScheduleListItem
                                    key={String(index)}
                                    id={index}
                                    lesson={scheduleItemData}
                                    isEmpty={isEmptyLesson}
                                    isShow={isShow}
                                    onItemClick={onScheduleItemClick}
                                />
                            );
                        })}
                    </ul>
                </div>
            ))}
        </div>
    );
};
