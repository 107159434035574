import React from 'react';

import { CalendarDay } from './CalendarDay';
import { CalendarMonth } from './CalendarMonth';
import { CalendarWeek } from './CalendarWeek';
import { ScheduleBoardProps } from './ScheduleBoard.types';

const createLessonLabelStab = (count: number) => `${count} урок(ов)`;
const createCalendarLessonLabelStab = (count: number) => `${count} занятие(ий)`;
const createMeetingLabelStab = (count: number) => `${count} собрание(ий)`;
const createTaskLabelStab = (count: number) => `${count} ДЗ`;

export const ScheduleBoard: React.FC<ScheduleBoardProps> = ({
    periodTime = 'day',
    currentDate,
    scheduleData,
    taskData,
    isStudent,
    onDayClick,
    onScheduleItemClick,
    createLessonLabel = createLessonLabelStab,
    createCalendarLessonLabel = createCalendarLessonLabelStab,
    createMeetingLabel = createMeetingLabelStab,
    createTaskLabel = createTaskLabelStab,
    isLessonNeedHide,
    isMeetingNeedHide,
    isTaskNeedHide,
    isNeedHideLessonButton,
}) => {
    return (
        <>
            {periodTime === 'day' && (
                <CalendarDay
                    date={currentDate}
                    lessons={scheduleData?.[currentDate]?.data}
                    homeworks={taskData?.[currentDate]?.data}
                    assignedHomeworks={taskData?.[currentDate]?.assigned}
                    lessonCheck={!isLessonNeedHide}
                    meetingCheck={!isMeetingNeedHide}
                    taskCheck={!isTaskNeedHide}
                    isStudent={isStudent}
                    onScheduleItemClick={onScheduleItemClick}
                    createTaskLabel={createTaskLabel}
                    createLessonLabel={createLessonLabel}
                    createCalendarLessonLabel={createCalendarLessonLabel}
                    createMeetingLabel={createMeetingLabel}
                    isNeedHideLessonButton={isNeedHideLessonButton}
                />
            )}
            {periodTime === 'week' && (
                <CalendarWeek
                    date={currentDate}
                    dataMap={scheduleData}
                    tasksMap={taskData}
                    taskCheck={!isTaskNeedHide}
                    lessonCheck={!isLessonNeedHide}
                    meetingCheck={!isMeetingNeedHide}
                    isStudent={isStudent}
                    onScheduleItemClick={onScheduleItemClick}
                    createCalendarLessonLabel={createCalendarLessonLabel}
                    createTaskLabel={createTaskLabel}
                    createMeetingLabel={createMeetingLabel}
                    withBorder
                />
            )}
            {periodTime === 'month' && (
                <CalendarMonth
                    date={currentDate}
                    dataMap={scheduleData}
                    tasksMap={taskData}
                    lessonCheck={!isLessonNeedHide}
                    meetingCheck={!isMeetingNeedHide}
                    taskCheck={!isTaskNeedHide}
                    isStudent={isStudent}
                    onDayClick={onDayClick}
                />
            )}
        </>
    );
};
